import React, { Component } from "react";
import { Container } from "bloomer/lib/layout/Container";
import { Columns, Column, Label } from "bloomer";
import firebase from "firebase/compat/app";

import { DateRangePicker } from "react-dates";
import MyTable from "../components/admin/MyTable";
import { withWareCtx } from "../contexts/WarehouseContext";
import { withAdminCtx } from "../contexts/AdminContext";
import { withModalsCtx } from "../contexts/ModalsContext";
import { withRouter } from "react-router-dom";
import Loader from "../components/Loader";
import Utils from "../Utils";
import { TabList } from "bloomer/lib/components/Tabs/TabList";
import { Tabs } from "bloomer/lib/components/Tabs/Tabs";
import { TabLink } from "bloomer/lib/components/Tabs/TabLink";
import { Tab } from "bloomer/lib/components/Tabs/Tab";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "moment/locale/it";
import DReportGrafici from "./DReportGrafici";
import { Help } from "bloomer/lib/elements/Form/Help";
import { Checkbox } from "bloomer/lib/elements/Form/Checkbox";
import DocumentType from "../enums/DocumentType";
import { ExportToCsv } from "export-to-csv-file";
import DateUtils from "../services/DateUtils";
import {
  Box,
  Center,
  Heading,
  Progress,
  SkeletonCircle,
  SkeletonText,
  Switch,
  Button,
  Stack,
  InputGroup,
  InputLeftAddon,
  Input,
  Flex,
  Tooltip,
  Divider,
  Text,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  IconButton,
} from "@chakra-ui/react";
import {
  calcClientiAggregate,
  calcProdottiAggregate,
  getAmazonCountry,
  getProductPezzi,
  getWarehouseProducts,
  groupBy,
  ordersGroupBy,
} from "./KPIUtils";
import DReportLVG from "./DReportLVG";
import { ExporterButton } from "../components/DExporterButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select } from "bloomer/lib/elements/Form/Select";
const fs = require("fs");

var moment = require("moment");
export const START_YEAR = 2019; //AAA 2019

class DReportsKPI extends Component {
  state = {
    loadingStorico: false,
    documents: [],
    yearsLoaded: [],
    affiliatiDocs: [],
    warehouseClienti: [],
    warehouseDocs: [],
    affiliatiDocsFiltered: [],
    escludiStorico: true,
    dateChanged: true,
    searchValue: "",
    searchValue2: "",
    searchValue3: "",
    searchValue4: "",
    searchValue5: "",
    insertedRow: 0,
    deletedRow: 0,
    riepilogoData: [],
    tab: "kpi",
    nazioniAggregate: [],
    nazioniFilterValue: "Tutti",
    nazioniFilterActive: false,
    capAggregate: [],
    capFilterValue: "Tutti",
    capFilterActive: false,
    clienteFilterValue: "Tutti",
    clientiFilterActive: false,
    fatturatoTotale: 0,
    fatturatoTotaleScorporoIVA: 0,
    fatturatoTotaleIT: 0,
    fatturatoTotaleWOO: 0,
    fatturatoTotaleALTRI_IT: 0,
    fatturatoOffline: 0,
    fatturatoOfflineIT: 0,
    fatturatoMyrebranding: 0,
    fatturatoPromozioni: 0,
    fatturatoAffiliati: 0,
    fatturatoPartnership: 0,
    fatturatoAltreFonti: 0,
    pezziMedioTotale: 0,
    pezziMedioTotaleIT: 0,
    pezziMedioTotaleWOO: 0,
    pezziMedioTotaleALTRI_IT: 0,
    pezziMedioPromozioni: 0,
    pezziMedioAffiliati: 0,
    pezziMedioPartnership: 0,
    pezziMedioAltreFonti: 0,
    fatturatoMedioTotale: 0,
    fatturatoMedioTotaleIT: 0,
    fatturatoMedioTotaleWOO: 0,
    fatturatoMedioTotaleALTRI_IT: 0,
    fatturatoMedioPromozioni: 0,
    fatturatoMedioAffiliati: 0,
    fatturatoMedioPartnership: 0,
    fatturatoMedioAltreFonti: 0,
    shippingCost: 0,
    uniqueBuyers: [],
    clientiMyRebrandingUnique: [],
    clientiOfflineUnique: [],
    quantity_shipped_total: 0,
    clientiAggregate: [],
    clientiTotaliAggregate: [],
    prodottiAggregate: [],
    comboAggregate: [],
    data: [],
    filteredData: [],
    columns: [],
    date: [],
    calendarShowing: false,
    filteredDocs: [],
    startDate: moment(new Date()).add(-30, "days"),
    endDate: moment(new Date()),
    searchFields: [
      "buyer_name",
      "product",
      "cap",
      "country",
      "email",
      "sku",
      "comboSKUcodes",
      "cod",
      "piattaforma",
      "piattaforma_2",
      "brand",
      "order_id",
      "type",
      "combo",
      "codeNumber",
      "uid",
      "contact-name",
    ],
    affiliatiCostoMateriale: 0,
    affiliatiPezzi: 0,
    brandFilter: "-",
    countryFilter: "-",
    canaleFilter: "-",
  };

  constructor(props) {
    super(props);
    this.calculateReportTotals = this.calculateReportTotals.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  async deleteDB() {
    const { filteredData } = this.state;
    const promises = [];
    let index = 0;
    filteredData.forEach((d) => {
      promises.push(
        new Promise(async (r, rj) => {
          // if (d.piattaforma === "WOO") {
          console.log("AA Delete", d.piattaforma, d.uid);
          const contactsSnap = await firebase.firestore().collection("reports_kpi").doc(d.uid).delete();
          // }
          this.setState({ deletedRow: index });
          index++;
          r(d.uid);
        }),
      );
    });

    await Promise.all(promises);
  }

  getPriceFormatted(p) {
    if (!p) p = 0;
    // console.log("aa p", p);
    return parseFloat(p.toString().replace("€", "").replace(",", "."));
  }

  getCouponCategorie(row, coupon) {
    if (row.piattaforma == "WOO" && !!row.coupon_code && !!coupon[row.coupon_code.toString().toUpperCase()]) {
      row.coupon_categoria = coupon[row.coupon_code.toUpperCase()].categoria;
    }
  }

  getCountryCode(row, country) {
    if (!row.country) row.country = !!country[row.country_code] ? country[row.country_code].description : "";
  }

  async getProductInfo(row, prodotti) {
    let prodottiFiltered = [];
    if (row.piattaforma == "AMAZON") {
      if (!!row.amazonCountry) {
        prodottiFiltered = prodotti.filter((p) => p["amazon_" + row.amazonCountry] == row.sku);
      }

      if (prodottiFiltered?.length == 0) {
        prodottiFiltered = prodotti.filter(
          (p) => p.amz_prime == row.sku || p.amz_noi == row.sku || p.amz_ean === row.sku,
        );
      }
    } else {
      prodottiFiltered = prodotti.filter((p) => p.uid == row.sku);
    }

    if (prodottiFiltered.length > 0) {
      const { childProducts, products, productsTable } = this.props.admin.products;

      row.cod = prodottiFiltered[0].uid;
      if (!row.cod) console.log("AA row not product", row);

      const productDBData = productsTable[row.sku] || productsTable[row.cod];

      row.product = prodottiFiltered[0].name;
      row.brand = prodottiFiltered[0].brand;
      row.iva = row.price_of_items_vat_rate_percent === 0 ? 0 : prodottiFiltered[0].iva;
      row.combo = row.cod.includes("+");
      row.comboSKU = row.cod.split("+");
      row.omaggio = prodottiFiltered[0].omaggio;
      row.coupon = prodottiFiltered[0].coupon;

      // console.log(
      //   "productDBData",
      //   row.sku,
      //   productDBData,
      //   prodottiFiltered[0],
      //   prodottiFiltered[0].sku
      // );

      if (productDBData?.tipologia === "Combo prodotti" && productDBData?.codiciFigli?.length > 0) {
        row.combo = true;
        const comboSKU = [];
        const comboSKUcodes = [];
        productDBData.codiciFigli.forEach((c) => {
          comboSKU.push(c);
          comboSKUcodes.push(c.sku.toLowerCase());
        });

        row.comboSKU = comboSKU;
        row.comboSKUcodes = comboSKUcodes;
      }
    }
  }

  getRealDate(row) {
    if (row.piattaforma == "AMAZON") {
      row.purchase_date = row.payments_date;
    }
  }

  getRealPrice(row) {
    if (row.piattaforma == "AMAZON") {
      //Prendi dal report fiscale
      if (!!row.total_price_of_items_amt_vat_incl || !!row.total_ship_charge_amt_vat_incl) {
        row.shipping_price = this.getPriceFormatted(row.total_ship_charge_amt_vat_incl);
        row.item_price =
          this.getPriceFormatted(row.total_price_of_items_amt_vat_incl / row.quantity_shipped) +
          this.getPriceFormatted(row.total_gift_wrap_amt_vat_incl);
      } else {
        row.shipping_price =
          this.getPriceFormatted(row.shipping_price) + this.getPriceFormatted(row.ship_promotion_discount);
        row.item_price = this.getPriceFormatted(row.item_price) + this.getPriceFormatted(row.item_promotion_discount);
        if (row.piattaforma_2 == "PRIME") {
          row.shipping_price = 0;
          row.item_price =
            this.getPriceFormatted(row.item_price) +
            this.getPriceFormatted(row.item_promotion_discount) +
            this.getPriceFormatted(row.item_tax);
        }
      }
    } else if (row.piattaforma == "ALIEXPRESS") {
      row.item_price = this.getPriceFormatted(row.item_price) - this.getPriceFormatted(row.store_promotion);
    } else if (row.piattaforma === "WOO") {
      row.item_price = (row.item_cost * (100 + row.iva)) / 100;

      if (!row.item_cost && row.order_status === "Rimborsato" && row.order_shipping_amount === 0) {
        row.item_price = row.order_total_amount;
        row.quantity_shipped = 1;
      }
    }
  }

  getShipAddressFormatted(row) {
    if (row.piattaforma === "AMAZON") {
      // if (row.piattaforma_2 === "PRIME") {
      //   // ship_address_formatted;
      // } else {
      row.ship_address_formatted =
        row.ship_address_1 + ", " + row.ship_city + ", " + row.ship_state + ", " + row.cap + ", " + row.country;
      row.phone = row.ship_phone_number || "-";
      // }
    } else if (row.piattaforma === "ALIEXPRESS") {
      row.ship_address_formatted = row.receiver_address + ", " + row.cap;
      row.phone = row.phone_ || "-";
    } else if (row.piattaforma === "WOO") {
      row.ship_address_formatted =
        row.address_1_2__shipping_ +
        ", " +
        row.city__shipping_ +
        ", " +
        row.state_code__shipping_ +
        ", " +
        row.cap +
        ", " +
        row.country;
      row.phone = row.phone__billing_ || "-";
    } else if (row.piattaforma === "EBAY") {
      row.ship_address_formatted =
        row.indirizzo_di_spedizione_1 +
        ", " +
        row.indirizzo_di_spedizione_2 +
        ", " +
        row.citt__di_spedizione +
        ", " +
        row.provincia_di_spedizione +
        ", " +
        row.cap +
        ", " +
        row.country;
      row.phone = row.telefono_destinatario || "-";
    }
  }

  getClientCode(row) {
    if (!row.buyer_name) {
      row.buyer_name = "-";
      row.codCliente = "-";
    } else {
      const text_content_array = row.buyer_name.split(" ");
      if (text_content_array.length >= 2)
        row.codCliente = text_content_array[0].substring(0, 3) + text_content_array[1].substring(0, 3) + row.cap;
      else if (!!row.email) row.codCliente = row.email;
      else if (text_content_array.length >= 1) row.codCliente = text_content_array[0] + row.cap;

      //console.log("FF cod client", row);
      row.codCliente = row.codCliente.toLowerCase().replace(/\s/g, "");
    }
  }

  async componentDidMount() {
    this.setState({ loadingCountries: true });
    await this.loadCountries();
    if (
      firebase.auth().currentUser.uid === "ui4tLgJvMnV4jzco5fbeKM2jKnc2" ||
      firebase.auth().currentUser.uid === "HkMUj0meTqQTKqyU9yruY2DFar43"
    )
      return;

    // this.loadData();
  }

  async loadCountries() {
    const country = [];
    const countryData = await firebase.firestore().collection("nations").get();

    countryData.forEach((c) => {
      country[c.data().uid] = {
        uid: c.data().uid,
        description: c.data().description,
        translation: c.data().translation || "",
      };
    });

    this.setState({ country, loadingCountries: false });
  }

  async loadData() {
    this.setState({ loading: true });
    const { country, startDate, endDate, escludiStorico } = this.state;

    let data = [];
    const prodotti = [];
    let filteredData = [];
    const coupon = [];

    const productData = await firebase.firestore().collection("reports_products").get();

    productData.forEach((p) => {
      prodotti.push(p.data());
    });

    const couponData = await firebase.firestore().collection("coupon_categories").get();

    couponData.forEach((p) => {
      coupon[p.data().uid] = {
        coupon: p.data().uid,
        categoria: p.data().categoria,
      };
    });

    let rowData = [];

    console.log("AA rowData before");

    if (escludiStorico) {
      rowData = await firebase
        .firestore()
        .collection("reports_kpi")
        .where("purchase_date", ">=", firebase.firestore.Timestamp.fromDate(new Date(startDate)))
        .where("purchase_date", "<=", firebase.firestore.Timestamp.fromDate(new Date(endDate)))
        // .where("piattaforma", "==", "WOO")
        .get();

      console.log("AAA rowdata", rowData);
      data = this.preparaData(rowData, country, prodotti, coupon);
    } else {
      console.log("YY CARICA DATI TOTALE");
      if (!!this.state.storicoData) data = data.concat(this.state.storicoData);
      // const startYear = 2024;
      // const currentYear = moment().year();
      const startYear = 2025;
      const currentYear = 2025;
      const years = [];
      for (let i = startYear; i <= currentYear; i++) years.push(i);

      for (const y of years) {
        const { yearsLoaded } = this.state;

        for (let m = 1; m <= 12; m++) {
          this.setState({ yearLoading: y, monthLoading: m, yearsLoaded });

          rowData = await this.loadDataOfYears(m, y);

          const yearData = this.preparaData(rowData, country, prodotti, coupon);
          console.log("AA yearData ", yearData);

          if (yearData && yearData.length > 0) data = data.concat(yearData);
          console.log("AA data ", data);

          yearsLoaded.push({ year: y, dati: data.length });
          console.log("AA rowData ", y, data);
        }
      }
    }

    // console.log(
    //   "AA DATAAAAA ",
    //   data.map(d => moment(d.purchase_date).format("DD-MM-YYYY")),
    //   data.map(d =>
    //     moment(DateUtils.firebaseTimeStampoToDate(d.purchase_date)).format(
    //       "DD-MM-YYYY"
    //     )
    //   ),

    //   data.map(d =>
    //     moment(DateUtils.timestampToDate(d.purchase_date)).format("DD-MM-YYYY")
    //   )
    // );

    console.log("AA rowData", rowData);
    this.setState({ formatting: true, prodotti });

    filteredData = data;

    this.setState({ loadB2B: true, formatting: false });
    if (this.state.tab !== "lvg") await this.loadWarehouseData();

    this.setState(
      {
        filteredData,
        data,
        loading: false,
        dateChanged: false,
        calculating: true,
        loadB2B: false,
      },
      () => this.doOperationOnAllData(),
    );

    //WRITE DATA
  }

  handleChange = async (e) => {
    let data = [];
    this.setState({ loadingStorico: true });
    const files = e.target.files ? [...e.target.files] : [];

    let promises = [];
    files.forEach((ee, ei) => {
      promises.push(
        new Promise(async (r, rj) => {
          const fileReader = new FileReader();
          fileReader.readAsText(e.target.files[ei], "UTF-8");
          fileReader.onload = async (eee) => {
            data = data.concat(JSON.parse(eee.target.result));
            console.log("AA DATA", data);
            r(ee);
          };
        }),
      );
    });
    await Promise.all(promises);
    console.log("AA DATA dine", data);
    this.setState({ storicoData: data, loadingStorico: false });
  };

  preparaData(rowData, country, prodotti, coupon) {
    const data = [];
    rowData.forEach((r) => {
      const row = r.data();

      row.item_price = this.getPriceFormatted(row.item_price);
      if (!!row.transaction_type && row.transaction_type === "RETURN") row.quantity_shipped *= -1;
      //get country
      this.getCountryCode(row, country);
      getAmazonCountry(row);
      this.getShipAddressFormatted(row);
      this.getProductInfo(row, prodotti);
      this.getClientCode(row);
      this.getRealDate(row);
      this.getRealPrice(row);
      this.getCouponCategorie(row, coupon);
      row.type = row.transaction_type || "SALE";
      if (row.order_status === "Rimborsato" && row.quantity_shipped < 0) row.type = "REFUND";
      row.orderUID = row.piattaforma + "_" + row.order_id + "_" + row.type;
      if (row.country.toLowerCase() === "italia") row.cap = this.padCap(row.cap);

      if (this.checkData(row)) data.push(row);
    });

    data.sort((a, b) => {
      if (a.purchase_date > b.purchase_date) return 1;
      if (a.purchase_date < b.purchase_date) return -1;
      return 0;
    });
    console.log("AA data", data);
    return data;
  }
  async loadDataOfYears(m, y) {
    console.log("vv current m y", m, y);

    const rowData = await firebase
      .firestore()
      .collection("reports_kpi")
      // .where("piattaforma", "==", "WOO")
      .where("purchase_date", ">=", firebase.firestore.Timestamp.fromDate(new Date(m + " 01 " + y)))
      .where("purchase_date", "<=", firebase.firestore.Timestamp.fromDate(new Date(m + " 31 " + y)))
      // .limit(1000) //AAA
      // .orderBy("purchase_date", "desc")
      .get();
    console.log("vv data ", y, rowData);

    return rowData;
  }

  checkData(row) {
    if (row.piattaforma == "WOO") {
      if (
        row.order_status === "Completato" ||
        row.order_status === "Completed" ||
        row.order_status === "Rimborsato" ||
        row.order_status === "Refunded"
      )
        return true;
      else return false;
    }

    return true;
  }

  getScorporoIvaDistinte(d) {
    d.scorporo_iva = 0;
    if (!d.products) return;
    d.products.forEach((p) => {
      const iva = (100 + p.iva) / 100;
      const sconto = (100 - p.sconto) / 100;
      d.scorporo_iva += (p.price_sell * p.quantity * iva * sconto) / iva;
    });
  }

  doOperationOnAllData() {
    const { data, endDate } = this.state;

    let clientiTotaliAggregate = {};
    // clientiTotaliAggregate = Object.values(
    // data.reduce((result, { email, buyer_name, quantity_shipped, item_price, cap, country }) => {

    let fatturatoTotaleWOO = 0;
    let fatturatoTotaleAT = 0;
    let fatturatoTotaleWOOIva = 0;
    let fatturatoTotaleATIva = 0;

    console.log("AA DATA", data);

    let dataWithRange = data.filter((d) => !!d.purchase_date && d.purchase_date.seconds <= endDate.valueOf() / 1000);

    dataWithRange.forEach((d) => {
      // if (d.iva != 10 && d.piattaforma != "WOO") console.log("ZZ iva al 22", d.piattaforma, d);
      const subtotal = d.item_price * d.quantity_shipped;
      const ivaProdotto = subtotal / ((100 + d.iva) / 100);
      //console.log("WW iva subtotal", subtotal, ivaProdotto, d.iva);
      if (d.piattaforma === "WOO") {
        fatturatoTotaleWOO += this.getPriceFormatted(subtotal);
        fatturatoTotaleWOOIva += this.getPriceFormatted(ivaProdotto);
      }
      fatturatoTotaleAT += this.getPriceFormatted(subtotal);
      fatturatoTotaleATIva += this.getPriceFormatted(ivaProdotto);

      // Create new group
      const buyer_name = !!d.buyer_name ? d.buyer_name.toLowerCase() : "-";
      if (!clientiTotaliAggregate[d.codCliente])
        clientiTotaliAggregate[d.codCliente] = {
          buyer_name,
          email: d.email,
          codCliente: d.codCliente,
          quantity_shipped: 0,
          fatturato: 0,
          num_ordini: 0,
          cap: d.cap,
          country: d.county,
        };

      let ordini = clientiTotaliAggregate[d.codCliente].ordini || [];
      ordini.push({
        ordine: d.uid,
        order_id: d.order_id,
        comboSKU: d.comboSkU,
        qtyProdotti: getProductPezzi(d),
        date: d.purchase_date,
        dateFormatted: moment(DateUtils.firebaseTimeStampoToDate(d.purchase_date)).format("DD-MM-YYYY"),
        piattaforma: d.piattaforma,
        fatturato: this.getPriceFormatted(subtotal),
        iva: this.getPriceFormatted(ivaProdotto),
      });
      const num_ordini = [...new Set(ordini.map((o) => o.order_id))].length;
      const ordersIds = [...new Set(ordini.map((o) => o.order_id))];
      ordini = ordini.sort((a, b) => {
        if (a.date > b.date) return 1;
        if (a.date < b.date) return -1;
        return 0;
      });
      const ordiniReali = ordersGroupBy(ordini, "order_id");
      // Append to group
      clientiTotaliAggregate[d.codCliente] = {
        buyer_name,
        email: d.email,
        codCliente: d.codCliente,
        quantity_shipped: clientiTotaliAggregate[d.codCliente].quantity_shipped + d.quantity_shipped,
        fatturato: clientiTotaliAggregate[d.codCliente].fatturato + d.item_price,
        num_ordini: num_ordini,
        ordersIds: ordersIds,
        fatturato_medio: (clientiTotaliAggregate[d.codCliente].fatturato + d.item_price) / num_ordini,
        cap: d.cap,
        ordini: ordini,
        ordiniReali: ordiniReali,
        test: "test",
        piattaforma: d.piattaforma,
        country: d.country,
        lastPurchaseDate: ordiniReali[ordiniReali.length - 1].dateFormatted,
        previousPurchaseDate: ordiniReali.length > 1 ? ordiniReali[ordiniReali.length - 2].dateFormatted : null,
      };
      //   return result;
      // }, {})
    });

    console.log("AA clientiTotaliAggregate", clientiTotaliAggregate);

    let clientiWOO = 0;
    let clientiTot = 0;
    const clientiTotaliLVG = [];
    Object.keys(clientiTotaliAggregate).forEach(function (codCliente) {
      const c = clientiTotaliAggregate[codCliente];
      if (c.piattaforma == "WOO") clientiWOO++;
      clientiTot++;

      clientiTotaliLVG.push(c);
    });

    console.log(
      "ww clientiTotaliAggregate",
      clientiTotaliLVG.filter((c) => c.qtyProdotti > 1),
    );
    const uniqueOrder = [...new Set(dataWithRange.map((c) => c.order_id))];
    let acquistiTotale = uniqueOrder.length;

    let acquistiTotaleWOO = [...new Set(dataWithRange.filter((c) => c.piattaforma === "WOO").map((c) => c.order_id))]
      .length;

    console.log(
      "VV clientiWOO",
      fatturatoTotaleAT - fatturatoTotaleWOO,
      clientiTot - clientiWOO,
      acquistiTotale - acquistiTotaleWOO,
      clientiTot - clientiWOO,
      acquistiTotale,
      fatturatoTotaleAT / clientiTot,
      acquistiTotale / clientiTot,
    );
    // 28/07/2022 cambiato da  (fatturatoTotaleAT / clientiTot) * (acquistiTotale / clientiTot)

    const lifeTimeValue = (fatturatoTotaleAT / clientiTot).toFixed(2);
    const lifeTimeValueWOO = (fatturatoTotaleWOO / clientiWOO).toFixed(2);
    const lifeTimeValueOther = ((fatturatoTotaleAT - fatturatoTotaleWOO) / (clientiTot - clientiWOO)).toFixed(2);
    const lifeTimeValueIva = (fatturatoTotaleATIva / clientiTot).toFixed(2);
    const lifeTimeValueWOOIva = (fatturatoTotaleWOOIva / clientiWOO).toFixed(2);
    const lifeTimeValueOtherIva = ((fatturatoTotaleATIva - fatturatoTotaleWOOIva) / (clientiTot - clientiWOO)).toFixed(
      2,
    );

    const { warehouseDocsAllTime } = this.state;

    let fatturatoOffline = 0;
    let fatturatoOfflineIVA = 0;

    let fatturatoMyrebranding = 0;
    let fatturatoMyrebrandingIVA = 0;

    let acquistiOffline = 0;
    let acquistiMyrebranding = 0;
    const docs = (warehouseDocsAllTime || [])
      .filter((d) => d.type == DocumentType.DISTINTA)
      .filter(
        (d) =>
          d.dateTimestamp.seconds <= endDate.valueOf() / 1000 &&
          (Utils.getCanaleVendita(d, "Offline") ||
            Utils.getCanaleVendita(d, "Rivenditori") ||
            Utils.getCanaleVendita(d, "Rebranding")) &&
          d.type == DocumentType.DISTINTA,
      );

    docs.forEach((d) => {
      this.getScorporoIvaDistinte(d);

      const speseSpedizione = !!d.speseSpedizione ? d.speseSpedizione : 0;
      if (
        Utils.getCanaleVendita(d, "Offline") ||
        Utils.getCanaleVendita(d, "Rivenditori") ||
        Utils.getCanaleVendita(d, "Rebranding")
      ) {
        fatturatoOffline += d.finalPrice - speseSpedizione;
        fatturatoOfflineIVA += d.scorporo_iva;

        acquistiOffline++;
      } else if (Utils.getCanaleVendita(d, "Myrebranding")) {
        fatturatoMyrebranding += d.finalPrice - speseSpedizione;
        fatturatoMyrebrandingIVA += d.scorporo_iva;

        acquistiMyrebranding++;
      }
    });

    const clientiOffline = docs.filter(
      (d) =>
        (Utils.getCanaleVendita(d, "Offline") ||
          Utils.getCanaleVendita(d, "Rivenditori") ||
          Utils.getCanaleVendita(d, "Rebranding")) &&
        d.type == DocumentType.DISTINTA,
    );
    console.log("vv clientioffline", clientiOffline);
    const clientiMyRebranding = docs.filter(
      (d) => Utils.getCanaleVendita(d, "Myrebranding") && d.type == DocumentType.DISTINTA,
    );
    const clientiOfflineUnique = [...new Set(clientiOffline.map((o) => o.contact.uid))];

    console.log("vv clientiOfflineUnique", clientiOfflineUnique);

    const clientiMyRebrandingUnique = [...new Set(clientiMyRebranding.map((o) => o.uid))];

    const lifeTimeValueOffline = (fatturatoOffline / clientiOfflineUnique.length).toFixed(2);
    const lifeTimeValueOfflineIva = (fatturatoOfflineIVA / clientiOfflineUnique.length).toFixed(2);
    const lifeTimeValueMyrebranding = (fatturatoMyrebranding / clientiMyRebrandingUnique.length).toFixed(2);
    const lifeTimeValueMyrebrandingIva = (fatturatoMyrebrandingIVA / clientiMyRebrandingUnique.length).toFixed(2);
    const lifeTimeValueB2B = (
      (fatturatoOffline + fatturatoMyrebranding) /
      (clientiOfflineUnique.length + clientiMyRebrandingUnique.length)
    ).toFixed(2);
    const lifeTimeValueB2BIva = (
      (fatturatoOfflineIVA + fatturatoMyrebrandingIVA) /
      (clientiOfflineUnique.length + clientiMyRebrandingUnique.length)
    ).toFixed(2);

    console.log("VV ltv ", fatturatoOffline, fatturatoMyrebranding);

    // const mediaPesata = [];

    // let length = 0;
    // Object.keys(clientiTotaliAggregate).forEach(function(codCliente) {
    //   const c = clientiTotaliAggregate[codCliente];
    //   if (!mediaPesata[c.num_ordini]) mediaPesata[c.num_ordini] = { num_ordini: c.num_ordini, total: 0 };
    //   mediaPesata[c.num_ordini] = { num_ordini: c.num_ordini, total: mediaPesata[c.num_ordini].total + 1 };

    //   length = length + 1;
    // });

    // console.log("AA mediaPesata", mediaPesata);

    // let lifeTimeValue = 0;
    // mediaPesata.forEach(m => {
    //   lifeTimeValue = lifeTimeValue + m.num_ordini * m.total;
    // });

    // lifeTimeValue = lifeTimeValue / length;

    // console.log("AA mediapesata", mediaPesata, media, clientiTotaliAggregate.length, fatturatoTotale);

    this.setState(
      {
        clientiTotaliAggregate,
        lifeTimeValue,
        lifeTimeValue,
        lifeTimeValueWOO,
        lifeTimeValueOther,
        lifeTimeValueIva,
        lifeTimeValueWOOIva,
        lifeTimeValueOtherIva,
        lifeTimeValueB2B,
        lifeTimeValueOffline,
        lifeTimeValueMyrebranding,
        lifeTimeValueB2BIva,
        lifeTimeValueOfflineIva,
        lifeTimeValueMyrebrandingIva,
        clientiTotaliLVG,
      },
      () => this.applyFilters(),
    );
  }

  async loadWarehouseData() {
    const { startDate, endDate, prodotti } = this.state;

    let docsDB = await firebase
      .firestore()
      .collection("documents")
      .where("type", "==", DocumentType.DISTINTA)
      // .where("type", "in", [
      //   DocumentType.ENTRATA,
      //   DocumentType.PREVENTIVO,
      //   DocumentType.DISTINTA
      // ])
      .get();

    let documents = [];

    docsDB.forEach((u) => {
      const data = Utils.formatDate(u.data().date);
      let doc = u.data();
      doc.date = data;
      doc.dateTimestamp = u.data().date;
      doc.codeNumber = Utils.getCodeNumber(u.data());
      doc.typeFormatted = u.data().type === DocumentType.DISTINTA ? "uscita" : u.data().type;

      documents.push(doc);
    });

    const docs = documents.filter(
      (d) =>
        d.dateTimestamp.seconds >= startDate.valueOf() / 1000 &&
        d.dateTimestamp.seconds <= endDate.valueOf() / 1000 &&
        d.type != "migrazione" &&
        d.type != "Correzione",
    );

    const docsAlltime = documents.filter(
      (d) => d.dateTimestamp.seconds < endDate.valueOf() / 1000 && d.type != "migrazione" && d.type != "Correzione",
    );

    let warehouseClientiAllTime = [];
    docsAlltime.forEach((w) => {
      warehouseClientiAllTime.push({
        uid: !!w.contact ? w.contact.uid : "",
        contact: w.contact,
        canaleVenditaMultiplo: w.canaleVenditaMultiplo,
        type: w.type,
      });
    });

    const affDocs = docs.filter((d) => d.causale == "Omaggio" && d.type == DocumentType.DISTINTA);

    const promises = [];
    const affiliatiDocs = [];

    affDocs.forEach((d) => {
      d.products.forEach(async (p) => {
        promises.push(
          new Promise(async (r, rj) => {
            // console.log("BB price", p.price_buy);
            if (!p.price_buy) {
              const productSnap = await firebase.firestore().collection("products").doc(p.uid).get();

              p.price_buy = !!productSnap.data() ? productSnap.data().price_buy : 0;

              // console.log("BB price buy", p.price_buy, p);
            }
            r(p);
          }),
        );
      });
      // console.log("BB doc", d);
      affiliatiDocs.push(d);
    });

    await Promise.all(promises);

    console.log("BB docssssss", affiliatiDocs);

    const warehouseData = [];
    docs.forEach((d) => {
      d.buyer_name = d.contact?.name;
      d.email = d.contact?.email;
      d.quantity_shipped = d.products?.length;
      d.cap = d.contact?.shippingAddress?.cap || d.contact?.billingAddress?.cap;
      d.country = d.contact?.shippingAddress?.nazione || d.contact?.billingAddress?.cap;
      this.getClientCode(d);
      d.orderUID = d.codeNumber;
      console.log("vv d.products", d);
      d.products?.forEach(async (p) => {
        promises.push(
          new Promise(async (r, rj) => {
            // console.log("BB price", p.price_buy);
            if (!p.sku) {
              const productSnap = await firebase.firestore().collection("products").doc(p.uid).get();

              p.sku = !!productSnap.data() ? productSnap.data().sku : undefined;

              // console.log("BB price buy", p.price_buy, p);
            }
            p.prezzo_acquisto = this.props.admin.products.productsTable[p.sku]?.prezzo_acquisto || 0;
            p.cod = p.sku;
            p.product = p.name;
            p.item_price = (parseFloat(p.price_sell) * (p.iva + 100)) / 100;

            p.quantity_shipped = p.quantity;
            p.b2b = true;
            this.getProductInfo(p, prodotti);
            warehouseData.push({ ...d, ...p });
            r(p);
          }),
        );
      });
      // console.log("BB doc", d);
      //affiliatiDocs.push(d);
    });
    console.log("ww warehouseData", warehouseData);

    console.log(
      "AA warehouse docs",
      docs.map((d) => d.number),
    );

    this.setState({
      affiliatiDocs,
      documents,
      warehouseData,
      warehouseDocs: docs,
      warehouseDocsAllTime: docsAlltime,
      warehouseClientiAllTime,
    });
  }

  async onDatesChange(startDate, endDate) {
    this.setState({ startDate, endDate, dateChanged: true });

    if (!startDate || !endDate) return;

    // startDate = startDate.utcOffset(0);
    // endDate = endDate.utcOffset(0);
    if (startDate === endDate) {
      startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      startDate = moment(startDate);

      endDate = moment(startDate).add(1, "days");
      endDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

      console.log("VV single day");
    } else {
      startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      endDate.set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
    }
    console.log("onDateChange", startDate, endDate);
  }

  caricaDati() {
    this.setState({ calculating: true });

    if (this.state.escludiStorico) {
      this.loadData();
    } else {
      this.applyFilters();
      this.doOperationOnAllData();
    }
  }

  searchFilter(data, products = false) {
    if (!data) return data;
    const { searchValue, searchValue2, searchValue3, searchValue4, searchValue5 } = this.state;

    return data
      .filter((p) => this.checkSearchFied(searchValue, p, products))
      .filter((p) => this.checkSearchFied(searchValue2, p, products))
      .filter((p) => this.checkSearchFied(searchValue3, p, products))
      .filter((p) => this.checkSearchFied(searchValue4, p, products))
      .filter((p) => this.checkSearchFied(searchValue5, p, products));
  }

  async applyFilters() {
    let {
      affiliatiDocs,
      warehouseDocs,
      startDate,
      endDate,
      data,
      warehouseData,
      searchValue,
      brandFilter,
      countryFilter,
      canaleFilter,
    } = this.state;
    console.log("AA filtered data applyFilters", data, startDate, endDate, brandFilter, countryFilter, canaleFilter);
    // startDate = startDate.utcOffset(1);

    // startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    // endDate = moment(endDate).add(-1, "days");

    let filteredData = this.searchFilter(
      data.filter(
        (d) =>
          !!d.purchase_date &&
          d.purchase_date.seconds >= startDate.valueOf() / 1000 &&
          d.purchase_date.seconds <= endDate.valueOf() / 1000 &&
          (brandFilter != "-" ? d.brand === brandFilter : true) &&
          (countryFilter != "-" ? d.amazonCountry === countryFilter : true) &&
          (canaleFilter != "-"
            ? canaleFilter === "Rebranding"
              ? d.canaleVenditaMultiplo === "Rebranding"
              : canaleFilter === "WOO"
              ? d.piattaforma === "WOO"
              : canaleFilter === "B2B"
              ? d.type == DocumentType.DISTINTA
              : d.piattaforma !== "WOO" && d.type !== DocumentType.DISTINTA
            : true),
      ),
    );
    console.log("AA filtered data after", filteredData, canaleFilter);

    console.log("AA affiliatiDocsFiltereddata before", affiliatiDocs);

    let affiliatiDocsFiltered = affiliatiDocs.filter(
      (d) => !!d.contact && d.contact.name.toLowerCase().includes(searchValue.toLowerCase()),
    );
    const docs = this.searchFilter(
      this.state.documents.filter(
        (d) =>
          d.dateTimestamp.seconds >= startDate.valueOf() / 1000 &&
          d.dateTimestamp.seconds <= endDate.valueOf() / 1000 &&
          d.type === DocumentType.DISTINTA,
      ),
      false,
    );

    //Filtra prodotti all'interno dei documenti
    docs.forEach((d) => {
      let fatturatoDaScalare = 0;
      let fatturatoDaScalareScorporo = 0;

      if (brandFilter != "-") {
        console.log("AZ filtering");
        d.products.forEach((p) => {
          if (p.brand === "MYLAB") p.brand === "MYLAB NUTRITION";
          if (p.brand != brandFilter) {
            fatturatoDaScalare += p.item_price * p.quantity;
            fatturatoDaScalareScorporo += parseFloat(p.price_sell) * p.quantity;
          }
        });
      }
      if (countryFilter != "-") {
        console.log("AZ filtering");
        d.products.forEach((p) => {
          if (p.amazonCountry != countryFilter) {
            fatturatoDaScalare += p.item_price * p.quantity;
            fatturatoDaScalareScorporo += parseFloat(p.price_sell) * p.quantity;
          }
        });
      }

      d.fatturato = d.finalPrice - fatturatoDaScalare;
      console.log("AZ fatturato", d.fatturato, d.finalPrice, fatturatoDaScalare);
      d.fatturatoScorporo = d.fatturatoScorporo - fatturatoDaScalareScorporo;
    });

    let filteredWarehouseData = this.searchFilter(
      (warehouseData || []).filter(
        (d) =>
          d.dateTimestamp.seconds >= startDate.valueOf() / 1000 &&
          d.dateTimestamp.seconds <= endDate.valueOf() / 1000 &&
          (Utils.getCanaleVendita(d, "Offline") ||
            Utils.getCanaleVendita(d, "Rivenditori") ||
            Utils.getCanaleVendita(d, "Rebranding")) &&
          d.type == DocumentType.DISTINTA &&
          (brandFilter != "-"
            ? d.brand === brandFilter
            : canaleFilter === "Rebranding"
            ? d.canaleVenditaMultiplo === "Rebranding"
            : true),
      ),
      false,
    );

    if (countryFilter !== "-" || (canaleFilter !== "-" && canaleFilter !== "B2B" && canaleFilter !== "Rebranding"))
      filteredWarehouseData = [];

    console.log("AZ docs", docs);
    console.log("AZ filteredWarehouseData", filteredWarehouseData);
    this.setState({
      affiliatiDocsFiltered,
      filteredData,
      filteredWarehouseData,
      warehouseDocs: docs,
      calculating: true,
    });
    console.log("AA filtered data", filteredData);

    this.setState({
      timer: setTimeout(async () => {
        this.calculateReportTotals();
      }, 1500),
    });

    // filteredData.forEach(d => {
    //   console.log("dd PAYMENTS DATE", moment(DateUtils.firebaseTimeStampoToDate(d.payments_date)), startDate, d.payments_date.seconds, startDate.valueOf() / 1000);
    // });
  }

  padCap(num) {
    var s = "000000000" + num;
    return s.substr(s.length - 5);
  }

  async calculateReportTotals() {
    if (this.state.tab === "lvg") {
      this.setState({ calculating: false });
      return;
    }

    this.setState({ calculating: true });
    const { filteredData, filteredWarehouseData, clientiTotaliAggregate, warehouseDocs, warehouseClientiAllTime } =
      this.state;
    // console.log("calculateReportTotals", filteredData);

    let fatturatoTotale = 0;
    let fatturatoTotaleScorporoIVA = 0;
    let fatturatoTotaleIT = 0;
    let fatturatoIvaIT = 0;
    let shippingIt = 0;

    let fatturatoTotaleWOO = 0;
    let fatturatoIvaWoo = 0;
    let shippingWoo = 0;

    let fatturatoTotaleALTRI_IT = 0;
    let fatturatoIvaALTRI_IT = 0;
    let shippingALTRI_IT = 0;

    let fatturatoPromozioni = 0;
    let fatturatoAffiliati = 0;
    let fatturatoPartnership = 0;
    let fatturatoAltreFonti = 0;
    let quantity_shipped_total = 0;
    let quantity_shipped_total_IT = 0;
    let quantity_shipped_total_WOO = 0;
    let quantity_shipped_total_ALTRI_IT = 0;
    let quantityPromozioni = 0;
    let quantityAffiliati = 0;
    let quantityPartnership = 0;
    let quantityAltreFonti = 0;

    let shippingCost = 0;

    const uniqueOrder = [...new Set(filteredData.map((c) => c.order_id))];
    console.log("HH uniqueOrder", uniqueOrder);
    let acquistiTotale = uniqueOrder.length;
    let acquistiTotaleIT = [...new Set(filteredData.filter((r) => r.country_code == "IT").map((c) => c.order_id))]
      .length;
    let acquistiTotaleWOO = [...new Set(filteredData.filter((r) => r.piattaforma == "WOO").map((c) => c.order_id))]
      .length;
    let acquistiTotaleALTRI_IT = [
      ...new Set(filteredData.filter((r) => r.piattaforma != "WOO" && r.country_code == "IT").map((c) => c.order_id)),
    ].length;

    let acquistiPromozioni = [
      ...new Set(
        filteredData
          .filter((r) => !!r.coupon_categoria && r.coupon_categoria.toLowerCase() == "promozioni")
          .map((c) => c.order_id),
      ),
    ].length;
    let acquistiAffiliati = [
      ...new Set(
        filteredData
          .filter((r) => !!r.coupon_categoria && r.coupon_categoria.toLowerCase() == "affiliati")
          .map((c) => c.order_id),
      ),
    ].length;
    let acquistiPartnership = [
      ...new Set(
        filteredData
          .filter((r) => !!r.coupon_categoria && r.coupon_categoria.toLowerCase() == "partnership")
          .map((c) => c.order_id),
      ),
    ].length;
    let acquistiAltreFonti = [
      ...new Set(
        filteredData
          .filter(
            (r) =>
              r.piattaforma == "WOO" &&
              ((!!r.coupon_categoria &&
                r.coupon_categoria.toLowerCase() != "partnership" &&
                r.coupon_categoria.toLowerCase() != "affiliati" &&
                r.coupon_categoria.toLowerCase() != "promozioni" &&
                r.coupon_categoria.toLowerCase() != "extrasconto" &&
                r.coupon_categoria.toLowerCase() != "non-cumulabili") ||
                !r.coupon_categoria),
          )
          .map((c) => c.order_id),
      ),
    ].length;

    const { childProducts } = this.props.admin.products;
    let fatturatoIVACHECK = 0;

    filteredData.forEach((r) => {
      const figlio = !!childProducts[r.cod];
      let qty = getProductPezzi(r);
      r.pezzi = qty;
      // console.log("zzz quantity_shipped_total", qty, quantity_shipped_total);
      // console.log("tt qty", r);
      quantity_shipped_total += qty;

      const subtotal = r.item_price * r.quantity_shipped;
      const ivaProdotto = subtotal / ((100 + r.iva) / 100);
      r.scorporo_iva = ivaProdotto;

      fatturatoIVACHECK += parseFloat(ivaProdotto);
      r.fatturato = this.getPriceFormatted(subtotal);
      fatturatoTotale += this.getPriceFormatted(subtotal);
      fatturatoTotaleScorporoIVA += this.getPriceFormatted(ivaProdotto);
      // console.log("WW subtotal", subtotal, r.item_price, r.quantity_shipped, r);

      r.shippingCost = 0;
      if (r.piattaforma == "AMAZON") {
        r.shippingCost = this.getPriceFormatted(r.shipping_price);
      } else if (r.piattaforma == "EBAY") {
        r.shippingCost = r.spedizione_e_imballaggio;
      } else if (r.piattaforma == "WOO") {
        r.shippingCost =
          r.payment_method_title === "Pagamento alla consegna"
            ? r.order_shipping_amount + parseFloat(4.4)
            : r.order_shipping_amount;
        if (r.type === "REFUND" && r.order_shipping_amount <= 0) r.shippingCost = r.order_shipping_amount;
      } else if (r.piattaforma == "ALIEXPRESS") {
        r.shippingCost = r.shipping_cost;
      }

      if (r.country_code == "IT") {
        fatturatoTotaleIT += this.getPriceFormatted(subtotal);
        fatturatoIvaIT += this.getPriceFormatted(ivaProdotto);

        quantity_shipped_total_IT += qty;
      }
      if (r.piattaforma == "WOO") {
        fatturatoTotaleWOO += this.getPriceFormatted(subtotal);
        fatturatoIvaWoo += this.getPriceFormatted(ivaProdotto);

        quantity_shipped_total_WOO += qty;

        if (!!r.coupon_categoria) {
          if (r.coupon_categoria.toLowerCase() == "promozioni") {
            fatturatoPromozioni += this.getPriceFormatted(subtotal);
            quantityPromozioni += qty;
          }
          if (r.coupon_categoria.toLowerCase() == "affiliati") {
            fatturatoAffiliati += this.getPriceFormatted(subtotal);
            quantityAffiliati += qty;
          }
          if (r.coupon_categoria.toLowerCase() == "partnership") {
            fatturatoPartnership += this.getPriceFormatted(subtotal);
            quantityPartnership += qty;
          }
          if (
            r.coupon_categoria.toLowerCase() != "partnership" &&
            r.coupon_categoria.toLowerCase() != "affiliati" &&
            r.coupon_categoria.toLowerCase() != "promozioni" &&
            r.coupon_categoria.toLowerCase() != "extrasconto" &&
            r.coupon_categoria.toLowerCase() != "non-cumulabili"
          ) {
            fatturatoAltreFonti += this.getPriceFormatted(subtotal);
            quantityAltreFonti += qty;
          }
        } else {
          fatturatoAltreFonti += this.getPriceFormatted(subtotal);
          quantityAltreFonti += qty;
        }
      }
      if (r.piattaforma != "WOO" && r.country_code == "IT") {
        fatturatoTotaleALTRI_IT += this.getPriceFormatted(subtotal);
        fatturatoIvaALTRI_IT += this.getPriceFormatted(ivaProdotto);

        quantity_shipped_total_ALTRI_IT += qty;
      }
    });

    console.log("WW iva check", fatturatoIVACHECK, fatturatoTotaleScorporoIVA);

    const uniqueBuyers = [...new Set(filteredData.map((c) => c.codCliente))];

    let prodottiAggregate = [];
    let comboAggregate = [];
    let clientiAggregate = [];
    let ordiniAggregate = [];

    ordiniAggregate = Object.values(
      this.getAllDocuments().reduce(
        (
          result,
          { quantity_shipped, orderUID, item_price, cod, country_code, piattaforma, coupon_categoria, shippingCost },
        ) => {
          // Create new group
          if (!result[orderUID])
            result[orderUID] = {
              orderUID,
              num_prodotti: 0,
              fatturato_carrello: 0,
              country_code,
              piattaforma,
              coupon_categoria,
              shippingCost: 0,
              shippingCostTot: 0,
              tot_righe: 0,
            };

          const figlio = !!childProducts[cod];
          const qty = figlio ? childProducts[cod].quantity * quantity_shipped : quantity_shipped;

          // Append to group
          result[orderUID] = {
            orderUID,
            num_prodotti: result[orderUID].num_prodotti + qty,
            fatturato_carrello:
              result[orderUID].fatturato_carrello + this.getPriceFormatted(item_price * quantity_shipped),
            country_code,
            piattaforma,
            coupon_categoria,
            // shippingCost: piattaforma === "AMAZON" ? result[orderUID].shippingCost + shippingCost : (result[orderUID].shippingCost + shippingCost) / (result[orderUID].tot_righe + 1),
            shippingCostTot:
              piattaforma === "AMAZON"
                ? result[orderUID].shippingCost + shippingCost
                : result[orderUID].shippingCostTot + shippingCost,
            tot_righe: result[orderUID].tot_righe + 1,
          };

          result[orderUID] = {
            ...result[orderUID],
            shippingCost:
              piattaforma === "AMAZON" || piattaforma === "ALIEXPRESS"
                ? result[orderUID].shippingCostTot
                : result[orderUID].shippingCostTot / result[orderUID].tot_righe,
          };
          return result;
        },
        {},
      ),
    );

    console.log("AA ordiniAggregate", ordiniAggregate);

    shippingCost = 0;

    Object.keys(ordiniAggregate).forEach(function (orderUID) {
      const o = ordiniAggregate[orderUID];
      shippingCost += o.shippingCost;

      if (o.country_code == "IT") {
        shippingIt += o.shippingCost;
      }
      if (o.piattaforma == "WOO") {
        shippingWoo += o.shippingCost;
      }

      if (o.piattaforma != "WOO" && o.country_code == "IT") {
        shippingALTRI_IT += o.shippingCost;
      }
    });
    console.log("vv filteredData", filteredData);

    prodottiAggregate = calcProdottiAggregate(this.getAllDocuments(), this.props.admin.products.productsTable);

    this.setState({ prodottiAggregate });

    console.log("AA prodottiaggregate", prodottiAggregate);

    comboAggregate = Object.values(
      filteredData
        .filter((f) => !!f.combo)
        .reduce((result, { cod, product, item_price, quantity_shipped, brand, type, comboSKU }) => {
          let pezzi = 0;
          comboSKU.forEach((c) => {
            pezzi = c?.quantity ? c?.quantity * quantity_shipped : quantity_shipped;
          });

          let qty = type === "REFUND" ? 0 : quantity_shipped;

          // Create new group
          if (!result[cod])
            result[cod] = {
              cod,
              product,
              brand,
              fatturato: 0,
              quantity_shipped: 0,
              num_ordini: 0,
              pezzi: 0,
            };

          // Append to group
          result[cod] = {
            cod,
            product,
            brand,
            num_ordini: result[cod].num_ordini + 1,
            fatturato: result[cod].fatturato + quantity_shipped * item_price,
            quantity_shipped: result[cod].quantity_shipped + qty,
            pezzi: result[cod].pezzi + pezzi,
          };
          return result;
        }, {}),
    );

    this.setState({ comboAggregate });

    console.log("AA prodottiaggregate comboAggregate", comboAggregate);

    clientiAggregate = calcClientiAggregate(this.getAllDocuments(), clientiTotaliAggregate);

    this.setState({ clientiAggregate });
    console.log("AA clientiAggregate", clientiAggregate);

    let warehouseClienti = [];
    warehouseDocs.forEach((w) => {
      warehouseClienti.push({
        uid: !!w.contact ? w.contact.uid : "",
        contact: w.contact,
        canaleVenditaMultiplo: w.canaleVenditaMultiplo,
        type: w.type,
      });
      this.getScorporoIvaDistinte(w);
    });

    let fatturatoOffline = 0;
    let fatturatoOfflineIVA = 0;
    let shippingOffline = 0;

    let fatturatoOfflineIT = 0;
    let fatturatoOfflineIT_IVA = 0;
    let shippingOfflineIT = 0;

    let fatturatoMyrebranding = 0;
    let fatturatoMyrebrandingIVA = 0;
    let shippingMyrebranding = 0;

    let acquistiOffline = 0;
    let acquistiOfflineIT = 0;
    let acquistiMyrebranding = 0;

    let prodottiOffline = 0;
    let prodottiOfflineIT = 0;
    let prodottiMyrebranding = 0;

    let omaggiSito = 0;
    let omaggiAltriMarketplace = 0;
    let omaggiAffiliati = 0;
    let omaggiPartner = 0;
    let omaggiRivenditori = 0;
    let omaggiIstituzionali = 0;
    let omaggiAutoconsumo = 0;

    let omaggiSitoFatturato = 0;
    let omaggiAltriMarketplaceFatturato = 0;
    let omaggiAffiliatiFatturato = 0;
    let omaggiPartnerFatturato = 0;
    let omaggiRivenditoriFatturato = 0;
    let omaggiIstituzionaliFatturato = 0;
    let omaggiAutoconsumoFatturato = 0;
    let omaggiSitoFatturatoScorporo = 0;
    let omaggiAltriMarketplaceFatturatoScorporo = 0;
    let omaggiAffiliatiFatturatoScorporo = 0;
    let omaggiPartnerFatturatoScorporo = 0;
    let omaggiRivenditoriFatturatoScorporo = 0;
    let omaggiIstituzionaliFatturatoScorporo = 0;
    let omaggiAutoconsumoFatturatoScorporo = 0;

    console.log("AA warehouseDocs", warehouseDocs);
    console.log(
      "AA errore",
      filteredData.map((d) => d.brand),
    );
    const docs = warehouseDocs.filter((d) => d.type == DocumentType.DISTINTA);

    console.log("AA docs", docs);

    docs.forEach((d) => {
      //=> portare tutto dentro filteredWarehouse qua sotto
      let numProdotti = 0;
      d.products.forEach((p) => {
        const filterByBrand = this.state.brandFilter !== "-" ? p.brand === this.state.brandFilter : true;
        // if (p.combo) console.log("AA docs product", numProdotti, p);

        // if (filterByBrand) numProdotti += getProductPezzi(p);
        if (filterByBrand) numProdotti += p.quantity;
      });

      const speseSpedizione = !!d.speseSpedizione ? d.speseSpedizione : 0;

      if (d.causale === "Autoconsumo") {
        let costoOmaggio = 0;
        let costoOmaggioScorporo = 0;
        d.products.forEach((p) => {
          costoOmaggio += p.quantity * (isNaN(p.prezzo_acquisto) ? 0 : p?.prezzo_acquisto || 0);
          costoOmaggioScorporo +=
            (p.quantity * (isNaN(p.prezzo_acquisto) ? 0 : p?.prezzo_acquisto || 0)) / ((100 + (p.iva || 10)) / 100);
        });
        omaggiAutoconsumo++;
        omaggiAutoconsumoFatturato += costoOmaggio;
        omaggiAutoconsumoFatturatoScorporo += costoOmaggioScorporo;
      } else if (d.causale === "Omaggio") {
        let costoOmaggio = 0;
        let costoOmaggioScorporo = 0;
        d.products.forEach((p) => {
          costoOmaggio += p.quantity * (isNaN(p.prezzo_acquisto) ? 0 : p?.prezzo_acquisto || 0);
          costoOmaggioScorporo +=
            (p.quantity * (isNaN(p.prezzo_acquisto) ? 0 : p?.prezzo_acquisto || 0)) / ((100 + (p.iva || 10)) / 100);
        });

        if (Utils.getCanaleVendita(d, "Sito")) {
          omaggiSito++;
          omaggiSitoFatturato += costoOmaggio;
          omaggiSitoFatturatoScorporo += costoOmaggioScorporo;
        } else if (Utils.getCanaleVendita(d, "Altri marketplace")) {
          omaggiAltriMarketplace++;
          omaggiAltriMarketplaceFatturato += costoOmaggio;
          omaggiAltriMarketplaceFatturatoScorporo += costoOmaggioScorporo;
        } else if (Utils.getCanaleVendita(d, "Affiliati")) {
          omaggiAffiliati++;
          omaggiAffiliatiFatturato += costoOmaggio;
          omaggiAffiliatiFatturatoScorporo += costoOmaggioScorporo;
        } else if (Utils.getCanaleVendita(d, "Partner")) {
          omaggiPartner++;
          omaggiPartnerFatturato += costoOmaggio;
          omaggiPartnerFatturatoScorporo += costoOmaggioScorporo;
        } else if (Utils.getCanaleVendita(d, "Rivenditori") || Utils.getCanaleVendita(d, "Rebranding")) {
          omaggiRivenditori++;
          omaggiRivenditoriFatturato += costoOmaggio;
          omaggiRivenditoriFatturatoScorporo += costoOmaggioScorporo;
        } else if (Utils.getCanaleVendita(d, "Istituzionali")) {
          omaggiIstituzionali++;
          omaggiIstituzionaliFatturato += costoOmaggio;
          omaggiIstituzionaliFatturatoScorporo += costoOmaggioScorporo;
        }
      }

      if (
        Utils.getCanaleVendita(d, "Offline") ||
        Utils.getCanaleVendita(d, "Rivenditori") ||
        Utils.getCanaleVendita(d, "Rebranding")
      ) {
        fatturatoOffline += d.fatturato - speseSpedizione;

        fatturatoOfflineIVA += d.fatturatoScorporo;
        shippingOffline += speseSpedizione;

        prodottiOffline += numProdotti;
        const nazione = !!d.contact.shippingAddress
          ? d.contact.shippingAddress.nazione
          : !!d.contact.billingAddress
          ? d.contact.billingAddress.nazione
          : "Italia";
        if (nazione == "Italia") {
          fatturatoOfflineIT += d.finalPrice - speseSpedizione;
          fatturatoOfflineIT_IVA += d.scorporo_iva;
          shippingOfflineIT += speseSpedizione;

          acquistiOfflineIT++;
          prodottiOfflineIT += numProdotti;
        }

        acquistiOffline++;
      } else if (Utils.getCanaleVendita(d, "Myrebranding")) {
        fatturatoMyrebranding += d.finalPrice - speseSpedizione;
        fatturatoMyrebrandingIVA += d.scorporo_iva;
        shippingMyrebranding += speseSpedizione;

        acquistiMyrebranding++;
        prodottiMyrebranding += numProdotti;
      }
    });

    let fatturatoTotaleProdotti = 0;
    let fatturatoTotaleScorporoIVAProdotti = 0;
    let prodottiOfflineProdotti = 0;
    filteredWarehouseData.forEach((d) => {
      if (
        Utils.getCanaleVendita(d, "Offline") ||
        Utils.getCanaleVendita(d, "Rivenditori") ||
        Utils.getCanaleVendita(d, "Rebranding")
      ) {
        const pezzi = getProductPezzi(d);
        fatturatoTotaleProdotti += d.item_price * pezzi;
        fatturatoTotaleScorporoIVAProdotti += parseFloat(d.price_sell) * pezzi;
        prodottiOfflineProdotti += pezzi;
      }
    });

    const clientiOffline = warehouseClienti.filter(
      (d) =>
        (Utils.getCanaleVendita(d, "Offline") ||
          Utils.getCanaleVendita(d, "Rivenditori") ||
          Utils.getCanaleVendita(d, "Rebranding")) &&
        d.type == DocumentType.DISTINTA,
    );
    const clientiMyRebranding = warehouseClienti.filter(
      (d) => Utils.getCanaleVendita(d, "Myrebranding") && d.type == DocumentType.DISTINTA,
    );
    const clientiOfflineUnique = [...new Set(clientiOffline.map((o) => o.contact.uid))];

    console.log("AA acquirenti offline", clientiOffline, warehouseClienti, clientiOfflineUnique);

    const warehouseRecurrentClienti = [];
    warehouseClienti.forEach((c) => {
      if (warehouseClientiAllTime.filter((w) => w.uid === c.uid).length > 1) warehouseRecurrentClienti.push(c);
    });

    const clientiOfflineR = warehouseRecurrentClienti.filter(
      (d) =>
        (Utils.getCanaleVendita(d, "Offline") ||
          Utils.getCanaleVendita(d, "Rivenditori") ||
          Utils.getCanaleVendita(d, "Rebranding")) &&
        d.type == DocumentType.DISTINTA,
    );
    const clientiMyRebrandingR = warehouseRecurrentClienti.filter(
      (d) => Utils.getCanaleVendita(d, "Myrebranding") && d.type == DocumentType.DISTINTA,
    );
    const clientiOfflineUniqueR = [...new Set(clientiOfflineR.map((o) => o.uid))];

    console.log("AA acquirenti test", warehouseClientiAllTime, warehouseRecurrentClienti, clientiOfflineR);

    console.log("omaggiSitoFatturato", omaggiSitoFatturato);
    this.setState({
      clientiOfflineUnique,
      clientiOfflineUniqueR,
      ordiniAggregate,
      warehouseClienti,
      prodottiAggregate,
      clientiAggregate,
      fatturatoOffline,
      fatturatoOfflineIVA,
      shippingOffline,
      fatturatoOfflineIT,
      fatturatoOfflineIT_IVA,
      shippingOfflineIT,
      fatturatoMyrebranding,
      fatturatoMyrebrandingIVA,
      shippingMyrebranding,
      fatturatoTotale,
      fatturatoTotaleScorporoIVA,
      fatturatoTotaleIT,
      fatturatoIvaIT,
      shippingIt,
      fatturatoTotaleWOO,
      fatturatoIvaWoo,
      shippingWoo,
      fatturatoTotaleALTRI_IT,
      fatturatoIvaALTRI_IT,
      shippingALTRI_IT,
      fatturatoPromozioni,
      fatturatoAffiliati,
      fatturatoPartnership,
      fatturatoAltreFonti,
      shippingCost,
      uniqueBuyers,
      quantity_shipped_total,
      quantity_shipped_total_IT,
      quantity_shipped_total_WOO,
      quantity_shipped_total_ALTRI_IT,
      quantityPromozioni,
      quantityAffiliati,
      quantityPartnership,
      quantityAltreFonti,
      prodottiOffline,
      prodottiOfflineIT,
      prodottiMyrebranding,
      acquistiTotale,
      acquistiTotaleIT,
      acquistiTotaleWOO,
      acquistiTotaleALTRI_IT,
      acquistiOffline,
      acquistiOfflineIT,
      acquistiMyrebranding,
      acquistiPromozioni,
      acquistiAffiliati,
      acquistiPartnership,
      acquistiAltreFonti,
      omaggiSito,
      omaggiAffiliati,
      omaggiPartner,
      omaggiRivenditori,
      omaggiIstituzionali,
      omaggiAltriMarketplace,
      omaggiSitoFatturato,
      omaggiAltriMarketplaceFatturato,
      omaggiAffiliatiFatturato,
      omaggiPartnerFatturato,
      omaggiRivenditoriFatturato,
      omaggiIstituzionaliFatturato,
      omaggiSitoFatturatoScorporo,
      omaggiAltriMarketplaceFatturatoScorporo,
      omaggiAffiliatiFatturatoScorporo,
      omaggiPartnerFatturatoScorporo,
      omaggiRivenditoriFatturatoScorporo,
      omaggiIstituzionaliFatturatoScorporo,
      omaggiAutoconsumo,
      omaggiAutoconsumoFatturato,
      omaggiAutoconsumoFatturatoScorporo,
      fatturatoTotaleProdotti,
      fatturatoTotaleScorporoIVAProdotti,
      prodottiOfflineProdotti,
      calculating: false,
    });
  }

  getAllDocuments() {
    const { filteredData, filteredWarehouseData } = this.state;
    return filteredData.concat(filteredWarehouseData);
  }

  renderTotali() {
    const {
      warehouseClienti,
      warehouseClientiAllTime,
      affiliatiDocsFiltered,
      filteredData,
      acquistiTotale,
      acquistiOffline,
      acquistiMyrebranding,
      clientiMyRebrandingUnique,
      clientiOfflineUnique,
      prodottiOffline,
      prodottiMyrebranding,
      fatturatoTotale,
      fatturatoTotaleScorporoIVA,
      fatturatoTotaleIT,
      shippingCost,
      uniqueBuyers,
      quantity_shipped_total,
      fatturatoOfflineIVA,
      fatturatoMyrebrandingIVA,
      clientiAggregate,
      lifeTimeValue,
      fatturatoOffline,
      fatturatoMyrebranding,
      shippingMyrebranding,
      shippingOffline,
      fatturatoTotaleProdotti,
      fatturatoTotaleScorporoIVAProdotti,
      prodottiOfflineProdotti,
    } = this.state;

    // console.log("AA recurrent / totali", recurrentClienti, clientiAggregate.length);
    // console.log("AA fatturatoTotale - shippingCost", fatturatoTotale, shippingCost);

    let totPezzi = 0;
    let totAcquisto = 0;
    let totVendita = 0;

    affiliatiDocsFiltered.forEach((d) => {
      let totalPriceSell = 0;
      let totalPriceBuy = 0;
      let numeroPezzi = 0;
      // console.log("CC doc", d);
      d.products.forEach(async (p) => {
        const price_sell = p.price_sell || 0;
        totalPriceSell += p.quantity * price_sell;
        const price_buy = p.price_buy || 0;
        totalPriceBuy += p.quantity * price_buy;
        numeroPezzi += p.quantity;
      });

      d.totalPriceSell = totalPriceSell;
      d.totalPriceBuy = totalPriceBuy;

      totPezzi += numeroPezzi;
      totAcquisto += d.totalPriceBuy;
      totVendita += d.totalPriceSell;
    });

    console.log("ZZZZ fatturato", fatturatoTotale, fatturatoOffline, shippingCost, shippingOffline);
    console.log("CC buyers", uniqueBuyers, clientiOfflineUnique);

    return (
      <>
        {/* <Box p={5} bg="#efeff5" borderRadius="md">
          <Heading size="md">DOCUMENTI</Heading>
          <Flex>
            <Stat>
              <StatLabel>Fatturato totale</StatLabel>
              <StatNumber>
                {Utils.stringToCurrency(
                  fatturatoTotale +
                    fatturatoOffline +
                    parseFloat(shippingCost || 0) +
                    parseFloat(shippingOffline || 0)
                )}
              </StatNumber>
              <StatHelpText>
                Fatturato{" "}
                {Utils.stringToCurrency(fatturatoTotale + fatturatoOffline)}
              </StatHelpText>{" "}
              <StatHelpText mt={-2}>
                Spedizioni{" "}
                {Utils.stringToCurrency(
                  parseFloat(shippingCost || 0) + parseFloat(shippingOffline)
                )}
              </StatHelpText>
            </Stat>
            <Stat>
              <StatLabel>Fatturato</StatLabel>
              <StatNumber>
                {Utils.stringToCurrency(fatturatoTotale + fatturatoOffline)}
              </StatNumber>
              <StatHelpText>
                Scorporo IVA{" "}
                {Utils.stringToCurrency(
                  fatturatoTotaleScorporoIVA +
                    fatturatoOfflineIVA +
                    fatturatoMyrebrandingIVA
                )}
              </StatHelpText>{" "}
              <StatHelpText mt={-2}>
                IVA{" "}
                {Utils.stringToCurrency(
                  fatturatoTotale +
                    fatturatoOffline +
                    fatturatoMyrebranding -
                    fatturatoTotaleScorporoIVA -
                    fatturatoOfflineIVA -
                    fatturatoMyrebrandingIVA
                )}
              </StatHelpText>
            </Stat>
            <Stat>
              <StatLabel>Acquisti totale</StatLabel>
              <StatNumber>{acquistiTotale + acquistiOffline}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Acquirenti unici totale</StatLabel>
              <StatNumber>
                {uniqueBuyers?.length + clientiOfflineUnique?.length}
              </StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Pezzi acquistati</StatLabel>
              <StatNumber>
                {quantity_shipped_total +
                  prodottiOffline +
                  prodottiMyrebranding}
              </StatNumber>
            </Stat>
          </Flex>
        </Box> */}
        <Box mt={3} p={5} bg="#efeff5" borderRadius="md">
          <Heading size="md">PRODOTTI</Heading>
          <Flex justify={"start"}>
            <Stat>
              <StatLabel>Fatturato</StatLabel>
              <StatNumber>{Utils.stringToCurrency(fatturatoTotale + fatturatoTotaleProdotti)}</StatNumber>
              <StatHelpText>
                Scorporo IVA {Utils.stringToCurrency(fatturatoTotaleScorporoIVA + fatturatoTotaleScorporoIVAProdotti)}
              </StatHelpText>{" "}
              <StatHelpText mt={-2}>
                IVA{" "}
                {Utils.stringToCurrency(
                  fatturatoTotale +
                    fatturatoTotaleProdotti -
                    (fatturatoTotaleScorporoIVA + fatturatoTotaleScorporoIVAProdotti),
                )}
              </StatHelpText>
            </Stat>
            <Stat>
              <StatLabel>Acquisti totale</StatLabel>
              <StatNumber>{acquistiTotale + acquistiOffline}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Acquirenti unici totale</StatLabel>
              <StatNumber>{clientiAggregate.length}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Pezzi acquistati</StatLabel>
              <StatNumber>{quantity_shipped_total + prodottiOfflineProdotti}</StatNumber>
            </Stat>
          </Flex>
        </Box>
      </>
    );
  }

  renderInfo(title, text, props) {
    return (
      <Flex align="center" {...props}>
        <Heading size="sm">{title}</Heading>
        <Tooltip
          hasArrow
          p={3}
          label={
            <>
              <Heading size="sm">{text}</Heading>
            </>
          }
        >
          <Button
            ml={2}
            variant="solid"
            colorScheme="green"
            label="i"
            rounded={"50%"}
            size="xs"
            fontSize={"11px"}
            minW={"10px"}
            w={"10px"}
            height={"15px"}
          >
            i
          </Button>
        </Tooltip>
      </Flex>
    );
  }

  renderKPI() {
    const {
      clientiOfflineUnique,
      clientiOfflineUniqueR,
      warehouseClienti,
      warehouseClientiAllTime,
      affiliatiDocsFiltered,
      filteredData,
      acquistiTotale,
      acquistiTotaleIT,
      acquistiTotaleWOO,
      acquistiTotaleALTRI_IT,
      acquistiOffline,
      acquistiOfflineIT,
      acquistiMyrebranding,
      acquistiPromozioni,
      acquistiAffiliati,
      acquistiPartnership,
      acquistiAltreFonti,
      fatturatoTotale,
      fatturatoTotaleScorporoIVA,
      shippingCost,
      fatturatoTotaleIT,
      fatturatoIvaIT,
      shippingIt,
      fatturatoTotaleALTRI_IT,
      fatturatoIvaALTRI_IT,
      shippingALTRI_IT,
      fatturatoTotaleWOO,
      fatturatoIvaWoo,
      shippingWoo,
      fatturatoOffline,
      fatturatoOfflineIVA,
      shippingOffline,
      fatturatoOfflineIT,
      fatturatoOfflineIT_IVA,
      shippingOfflineIT,
      fatturatoMyrebranding,
      fatturatoMyrebrandingIVA,
      shippingMyrebranding,
      fatturatoPromozioni,
      fatturatoAffiliati,
      fatturatoPartnership,
      fatturatoAltreFonti,
      uniqueBuyers,
      quantity_shipped_total,
      quantity_shipped_total_IT,
      quantity_shipped_total_WOO,
      quantity_shipped_total_ALTRI_IT,
      quantityPromozioni,
      quantityAffiliati,
      quantityPartnership,
      quantityAltreFonti,
      prodottiOffline,
      prodottiOfflineProdotti,
      prodottiOfflineIT,
      prodottiMyrebranding,
      clientiAggregate,
      lifeTimeValue,
      lifeTimeValueWOO,
      lifeTimeValueOther,
      lifeTimeValueIva,
      lifeTimeValueWOOIva,
      lifeTimeValueOtherIva,
      lifeTimeValueB2B,
      lifeTimeValueOffline,
      lifeTimeValueMyrebranding,
      lifeTimeValueB2BIva,
      lifeTimeValueOfflineIva,
      lifeTimeValueMyrebrandingIva,
    } = this.state;

    let recurrentClienti = 0;
    let nuoviClienti = 0;
    let B2C_woo = 0;
    let recurrent_woo = 0;

    clientiAggregate.forEach((c) => {
      const woo = c.piattaforme.filter((p) => p === "WOO").length > 0;
      if (c.ordini_storico == c.num_ordini) nuoviClienti++;
      else {
        recurrentClienti++;
        recurrent_woo += woo ? 1 : 0;
      }

      B2C_woo += woo ? 1 : 0;
    });

    const rec = ((recurrentClienti / clientiAggregate.length) * 100).toFixed(2);
    // console.log("VV recurrent", rec, recurrentClienti, clientiAggregate.length);
    console.log("ZZZZ fatturatoTotale", fatturatoTotale, fatturatoOffline, fatturatoMyrebranding);
    return (
      <div>
        <div style={{ background: "rgb(152 152 158)", marginTop: "20px" }} className="ta-c">
          <b>PERFORMANCE CLIENTI</b>
        </div>

        <div style={{ background: "#efeff5" }}>
          {this.renderInfo("BUYERS", "I Buyer sono tutti gli acquirenti di MyLab", {
            justifyContent: "center",
            bg: "rgb(206, 206, 218)",
          })}
          <Columns style={{ width: "100%" }}>
            <Column>
              {this.renderInfo(
                "B2C " + uniqueBuyers.length,
                "Nr Acquirenti totale come somma tra B2C Nostro e B2C altri Marketplace",
              )}
              {this.renderInfo("B2C nostro sito " + B2C_woo, "Nr Acquirenti totale provenienti da Woocommerce")}
              {this.renderInfo(
                "B2C altri Marketplace " + parseInt(uniqueBuyers?.length - B2C_woo).toString(),
                "Nr Acquirenti totale provenienti da Amazon, Ebay, Aliexpress. ",
              )}
            </Column>
            <Column>
              <h1>
                <b>B2B</b>
              </h1>
              {this.renderInfo(
                "Rivenditori " + clientiOfflineUnique?.length,
                "Nr di Acquirenti totale calcolato su distinte con causale vendita ed il flag canale Offline + Rivenditori. ",
              )}
            </Column>
          </Columns>
        </div>
        <div style={{ background: "#efeff5", marginTop: "20px" }}>
          {this.renderInfo(
            "NEW BUYERS",
            "I nuovi acquirenti sono tutti coloro che hanno acquistato per la prima volta nel periodo selezionato, rispetto all'intero db di MyLab",
            { justifyContent: "center", bg: "rgb(206, 206, 218)" },
          )}
          <Columns style={{ width: "100%" }}>
            <Column>
              {this.state.escludiStorico ? (
                <Button colorScheme="green" my={2} w="285px" onClick={() => this.doEscludiStorico()}>
                  ABILITA STORICO
                </Button>
              ) : (
                <div>
                  {this.renderInfo(
                    "B2C " + parseInt(uniqueBuyers?.length - recurrentClienti).toString(),
                    "Nr Nuovi Acquirenti totale come somma tra B2C Nostro e B2C altri Marketplace. ",
                  )}
                  {this.renderInfo(
                    "B2C nostro sito " + parseInt(B2C_woo - recurrent_woo).toString(),
                    "Nr Nuovi Acquirenti  totale provenienti da Woocommerce. ",
                  )}
                  {this.renderInfo(
                    "B2C altri Marketplace " +
                      parseInt(uniqueBuyers?.length - recurrentClienti - (B2C_woo - recurrent_woo)).toString(),
                    "Nr Nuovi Acquirenti  totale provenienti da Amazon, Ebay, Aliexpress.",
                  )}
                </div>
              )}
            </Column>
            <Column>
              <h1>
                <b>B2B</b>
              </h1>
              {this.renderInfo(
                "Rivenditori " + parseInt(clientiOfflineUnique?.length - clientiOfflineUniqueR?.length).toString(),
                "Nr di Nuovi Acquirenti totale calcolato su distinte con causale vendita ed il flag canale Offline + Rivenditori",
              )}
            </Column>
          </Columns>
        </div>
        <div style={{ background: "#efeff5", marginTop: "20px" }}>
          {this.renderInfo(
            "RECURRENT BUYERS",
            "I Recurrent sono tutti coloro che hanno acquistato nel periodo selezionato e almeno un'altra volta nell'intero db di MyLab",
            { justifyContent: "center", bg: "rgb(206, 206, 218)" },
          )}

          <Columns style={{ width: "100%" }}>
            <Column>
              {this.state.escludiStorico ? (
                <Button colorScheme="green" my={2} w="285px" onClick={() => this.doEscludiStorico()}>
                  ABILITA STORICO
                </Button>
              ) : (
                <div>
                  {this.renderInfo(
                    "B2C " + parseInt(recurrentClienti).toString(),
                    "Nr Acquirenti Ricorrenti totale come somma tra B2C Nostro e B2C altri Marketplace",
                  )}
                  {this.renderInfo(
                    "B2C nostro sito " + parseInt(recurrent_woo).toString(),
                    "Nr Acquirenti Ricorrenti totale provenienti da Woocommerce",
                  )}
                  {this.renderInfo(
                    "B2C altri Marketplace " + parseInt(recurrentClienti - recurrent_woo).toString(),
                    "Nr Acquirenti Ricorrenti totale provenienti da Amazon, Ebay, Aliexpress. ",
                  )}
                </div>
              )}
            </Column>
            <Column>
              <h1>
                <b>B2B</b>
              </h1>
              {this.renderInfo(
                "Rivenditori  " + parseInt(clientiOfflineUniqueR?.length).toString(),
                "Nr Acquirenti Ricorrenti totale calcolato su distinte con causale vendita ed il flag canale Offline + Rivenditori ",
              )}
            </Column>
          </Columns>
        </div>
        <div style={{ background: "#efeff5", marginTop: "20px" }}>
          {this.renderInfo(
            "RECURRENT BUYERS %",
            "I Recurrent sono tutti coloro che hanno acquistato nel periodo selezionato e almeno un'altra volta nell'intero db di MyLab",
            { justifyContent: "center", bg: "rgb(206, 206, 218)" },
          )}
          <Columns style={{ width: "100%" }}>
            <Column>
              {this.state.escludiStorico ? (
                <Button colorScheme="green" my={2} w="285px" onClick={() => this.doEscludiStorico()}>
                  ABILITA STORICO
                </Button>
              ) : (
                <div>
                  {this.renderInfo(
                    "B2C " + rec + "%",
                    "% Acquirenti Ricorrenti totale come somma tra B2C Nostro e B2C altri Marketplace",
                  )}
                  {this.renderInfo(
                    "B2C nostro sito " + Utils.percent(recurrent_woo / B2C_woo) + "%",
                    "% Acquirenti Ricorrenti totale provenienti da Woocommerce",
                  )}
                  {this.renderInfo(
                    "B2C altri Marketplace " +
                      Utils.percent((recurrentClienti - recurrent_woo) / (clientiAggregate?.length - B2C_woo)) +
                      "%",
                    "% Acquirenti Ricorrenti totale provenienti da Amazon, Ebay, Aliexpress. ",
                  )}
                </div>
              )}
            </Column>
            <Column>
              <h1>
                <b>B2B</b>
              </h1>
              {this.renderInfo(
                "B2B Offline " + Utils.percent(clientiOfflineUniqueR?.length / clientiOfflineUnique?.length) + "%",
                "% Acquirenti Ricorrenti totale calcolato su distinte con causale vendita ed il flag canale Offline + Rivenditori",
              )}
            </Column>
          </Columns>
        </div>
        <div style={{ background: "#efeff5", marginTop: "20px" }}>
          {this.renderInfo(
            "TOTALE LTV",
            "Il Life Time Value è calcolato come Media Acquisti Clienti * Media Fatturato per Cliente su un orizzonte temporale dall'ultimo giorno selezionato come data fino all'inizio del db. ",
            { justifyContent: "center", bg: "rgb(206, 206, 218)" },
          )}

          <Columns style={{ width: "100%" }}>
            <Column>
              {this.state.escludiStorico ? (
                <Button colorScheme="green" my={2} w="285px" onClick={() => this.doEscludiStorico()}>
                  ABILITA STORICO
                </Button>
              ) : (
                <div>
                  {this.renderInfo(
                    "B2C " +
                      Utils.stringToCurrency(lifeTimeValueIva) +
                      "+ iva " +
                      Utils.stringToCurrency(lifeTimeValue - lifeTimeValueIva) +
                      " = " +
                      Utils.stringToCurrency(lifeTimeValue),
                    "LTV calcolato come somma tra B2C Nostro Sito e B2C altri Marketplace",
                  )}

                  {this.renderInfo(
                    "B2C nostro sito " +
                      Utils.stringToCurrency(lifeTimeValueWOOIva) +
                      "+ iva " +
                      Utils.stringToCurrency(lifeTimeValueWOO - lifeTimeValueWOOIva) +
                      " = " +
                      Utils.stringToCurrency(lifeTimeValueWOO),
                    "LTV su ordini provenienti da WooCommerce",
                  )}
                  {this.renderInfo(
                    "B2C altri Marketplace " +
                      Utils.stringToCurrency(lifeTimeValueOtherIva) +
                      "+ iva " +
                      Utils.stringToCurrency(lifeTimeValueOther - lifeTimeValueOtherIva) +
                      " = " +
                      Utils.stringToCurrency(lifeTimeValueOther),
                    "LTV su ordini provenienti da Amazon, Ebay, Aliexpress",
                  )}
                </div>
              )}
            </Column>
            <Column>
              <h1>
                <b>B2B</b>
              </h1>
              {this.renderInfo(
                "B2B Offline " +
                  Utils.stringToCurrency(lifeTimeValueOfflineIva) +
                  "+ iva " +
                  Utils.stringToCurrency(lifeTimeValueOffline - lifeTimeValueOfflineIva) +
                  " = " +
                  Utils.stringToCurrency(lifeTimeValueOffline),
                "LTV medio calcolato su distinte calcolato su distinte con causale vendita ed il flag canale Offline + Rivenditori",
              )}
            </Column>
          </Columns>
        </div>

        <div style={{ background: "rgb(152 152 158)", marginTop: "20px" }} className="ta-c">
          <b>PERFORMANCE VENDITE</b>
        </div>

        <div style={{ background: "#efeff5" }}>
          {this.renderInfo(
            "Totale Fatturato",
            "Il Totale Fatturato è l'importo proveniente da tutte le vendite al netto IVA sommato con costo di spedizione al netto IVA + costo di contrassegno al netto IVA + costo di commissioni SmallPay al netto IVA",
            { justifyContent: "center", bg: "rgb(206, 206, 218)" },
          )}

          <Columns style={{ width: "100%" }}>
            <Column>
              {this.renderInfo(
                "Totale IT " +
                  Utils.stringToCurrency(fatturatoIvaIT) +
                  " + iva " +
                  Utils.stringToCurrency(fatturatoTotaleIT - fatturatoIvaIT) +
                  " + s.s. " +
                  Utils.stringToCurrency(shippingIt) +
                  " = " +
                  Utils.stringToCurrency(fatturatoTotaleIT + shippingIt),
                "Tutto il fatturato proveniente da tutti i canali in Italia ",
              )}

              {this.renderInfo(
                "Totale EU " +
                  Utils.stringToCurrency(fatturatoTotaleScorporoIVA - fatturatoIvaIT) +
                  " + iva " +
                  Utils.stringToCurrency(
                    fatturatoTotale - fatturatoTotaleIT - (fatturatoTotaleScorporoIVA - fatturatoIvaIT),
                  ) +
                  " + s.s. " +
                  Utils.stringToCurrency(shippingCost - shippingIt) +
                  " = " +
                  Utils.stringToCurrency(fatturatoTotale - fatturatoTotaleIT + (shippingCost || 0) - (shippingIt || 0)),
                "Tutto il fatturato proveniente da tutti i canali in Europa escluso Italia ",
              )}

              <br />

              {this.renderInfo(
                "B2C totale EU " +
                  Utils.stringToCurrency(fatturatoTotaleScorporoIVA) +
                  " + iva " +
                  Utils.stringToCurrency(fatturatoTotale - fatturatoTotaleScorporoIVA) +
                  " + s.s. " +
                  Utils.stringToCurrency(shippingCost) +
                  " = " +
                  Utils.stringToCurrency(fatturatoTotale + (shippingCost || 0)),
                "Tutto il fatturato come somma di B2C nostro Sito IT e B2C altri Marketplace ",
              )}

              <br />

              {this.renderInfo(
                "B2C nostro sito " +
                  Utils.stringToCurrency(fatturatoIvaWoo) +
                  " + iva " +
                  Utils.stringToCurrency(fatturatoTotaleWOO - fatturatoIvaWoo) +
                  " + s.s. " +
                  Utils.stringToCurrency(shippingWoo) +
                  " = " +
                  Utils.stringToCurrency(fatturatoTotaleWOO + shippingWoo),
                "Tutto il fatturato proveniente dal nostro sito ",
              )}

              <br />
              {this.renderInfo(
                "B2C altri marketplace " +
                  Utils.stringToCurrency(fatturatoTotaleScorporoIVA - fatturatoIvaWoo) +
                  " + iva " +
                  Utils.stringToCurrency(
                    fatturatoTotale - fatturatoTotaleWOO - (fatturatoTotaleScorporoIVA - fatturatoIvaWoo),
                  ) +
                  " + s.s. " +
                  Utils.stringToCurrency(shippingCost - shippingWoo) +
                  " = " +
                  Utils.stringToCurrency(fatturatoTotale - fatturatoTotaleWOO + shippingCost - shippingWoo),
                "Tutto il fatturato proveniente da Amazon + Ebay + Aliexpress ",
              )}
              {this.renderInfo(
                "B2C altri marketplace IT " +
                  Utils.stringToCurrency(fatturatoIvaALTRI_IT) +
                  " + iva " +
                  Utils.stringToCurrency(fatturatoTotaleALTRI_IT - fatturatoIvaALTRI_IT) +
                  " + s.s. " +
                  Utils.stringToCurrency(shippingALTRI_IT) +
                  " = " +
                  Utils.stringToCurrency(fatturatoTotaleALTRI_IT + shippingALTRI_IT),
                "Tutto il fatturato proveniente da Amazon + Ebay + Aliexpress in ITALIA ",
              )}

              {this.renderInfo(
                "B2C altri marketplace EU " +
                  Utils.stringToCurrency(fatturatoTotaleScorporoIVA - fatturatoIvaWoo - fatturatoIvaALTRI_IT) +
                  " + iva " +
                  Utils.stringToCurrency(
                    fatturatoTotale -
                      fatturatoTotaleWOO -
                      fatturatoTotaleALTRI_IT -
                      (fatturatoTotaleScorporoIVA - fatturatoIvaWoo - fatturatoIvaALTRI_IT),
                  ) +
                  " + s.s. " +
                  Utils.stringToCurrency(shippingCost - shippingWoo - shippingALTRI_IT) +
                  " = " +
                  Utils.stringToCurrency(
                    fatturatoTotale -
                      fatturatoTotaleWOO -
                      fatturatoTotaleALTRI_IT +
                      shippingCost -
                      shippingWoo -
                      shippingALTRI_IT,
                  ),
                "Tutto il fatturato proveniente da Amazon + Ebay + Aliexpress in EUROPA escluso ITALIA ",
              )}
            </Column>
            <Column>
              {this.renderInfo(
                "B2B totale " +
                  Utils.stringToCurrency(fatturatoOfflineIVA + fatturatoMyrebrandingIVA) +
                  " + iva " +
                  Utils.stringToCurrency(
                    fatturatoOffline + fatturatoMyrebranding - (fatturatoOfflineIVA + fatturatoMyrebrandingIVA),
                  ) +
                  " + s.s. " +
                  Utils.stringToCurrency(shippingOffline + shippingMyrebranding) +
                  " = " +
                  Utils.stringToCurrency(
                    fatturatoOffline + fatturatoMyrebranding + shippingOffline + shippingMyrebranding,
                  ),
                "Nr Totale Fatturato come somma tra B2B nostri marchi IT, B2B nostri marchi EU, Rebranding IT ",
              )}

              <br />
              {this.renderInfo(
                "Rivenditori " +
                  Utils.stringToCurrency(fatturatoOfflineIT_IVA) +
                  " + iva " +
                  Utils.stringToCurrency(fatturatoOfflineIT - fatturatoOfflineIT_IVA) +
                  " + s.s. " +
                  Utils.stringToCurrency(shippingOfflineIT) +
                  " = " +
                  Utils.stringToCurrency(fatturatoOfflineIT + shippingOfflineIT),
                "Nr Totale Fatturato calcolato su distinte con causale vendita ed il flag canale Offline + Rivenditoricon in anagrafica cliente/ indirizzo di fatturazione /campo Nazione / Italia",
              )}
              {this.renderInfo(
                "Amazon vendor " +
                  Utils.stringToCurrency(fatturatoOfflineIVA - fatturatoOfflineIT_IVA) +
                  " + iva " +
                  Utils.stringToCurrency(
                    fatturatoOffline - fatturatoOfflineIT - (fatturatoOfflineIVA - fatturatoOfflineIT_IVA),
                  ) +
                  " + s.s. " +
                  Utils.stringToCurrency(shippingOffline - shippingOfflineIT) +
                  " = " +
                  Utils.stringToCurrency(fatturatoOffline - fatturatoOfflineIT + shippingOffline - shippingOfflineIT),
                "Nr Totale Fatturato calcolato su distinte con causale vendita ed il flag canale Offline + Rivenditori ",
              )}

              <br />
            </Column>
          </Columns>
        </div>
        <div style={{ background: "#efeff5" }}>
          {this.renderInfo("Totale Ordini", "Il totale ordini è il numero degli ordini totali ", {
            justifyContent: "center",
            bg: "rgb(206, 206, 218)",
          })}

          <Columns style={{ width: "100%" }}>
            <Column>
              {this.renderInfo(
                "Totale IT " + acquistiTotaleIT,
                "Nr Ordini Totale proveniente da tutti i canali in Italia  ",
              )}
              {this.renderInfo(
                "Totale EU " + parseInt(acquistiTotale - acquistiTotaleIT).toString(),
                "Nr Ordini Totale  proveniente da tutti i canali in Europa escluso Italia  ",
              )}

              <br />
              {this.renderInfo(
                "B2C totale " + acquistiTotale,
                "Nr Ordini Totale  come somma di B2C nostro Sito IT e B2C altri Marketplace  ",
              )}

              <br />
              {this.renderInfo(
                "B2C nostro sito " + acquistiTotaleWOO,
                "Nr Ordini Totale proveniente dal nostro sito  ",
              )}

              <br />
              {this.renderInfo(
                "B2C altri marketplace totale" + parseInt(acquistiTotale - acquistiTotaleWOO).toString(),
                "Nr Ordini Totale  proveniente da Amazon + Ebay + Aliexpress  ",
              )}
              {this.renderInfo(
                "B2C altri marketplace IT" + acquistiTotaleALTRI_IT,
                "Nr Ordini Totale  proveniente da Amazon + Ebay + Aliexpress in ITALIA  ",
              )}
              {this.renderInfo(
                "B2C altri marketplace EU" +
                  parseInt(acquistiTotale - acquistiTotaleWOO - acquistiTotaleALTRI_IT).toString(),
                "Nr Ordini Totale  proveniente da Amazon + Ebay + Aliexpress in EUROPA escluso ITALIA  ",
              )}
            </Column>
            <Column>
              {this.renderInfo(
                "B2B totale " + parseInt(acquistiOffline + acquistiMyrebranding).toString(),
                "Nr Totale ordini come somma tra B2B nostri marchi IT, B2B nostri marchi EU, Rebranding IT  ",
              )}

              <br />
              {this.renderInfo(
                "Rivenditori " + acquistiOfflineIT,
                "Nr Totale ordini calcolato su distinte con causale vendita ed il flag canale Offline + Rivenditori con in anagrafica cliente/ indirizzo di fatturazione /campo Nazione / Italia ",
              )}
              {this.renderInfo(
                "Amazon Vendor " + parseInt(acquistiOffline - acquistiOfflineIT).toString(),
                "Nr Totale ordini calcolato su distinte con causale vendita ed il flag canale Offline + Rivenditori ",
              )}
            </Column>
          </Columns>
        </div>
        <div style={{ background: "#efeff5" }}>
          {this.renderInfo(
            "Numero Prodotti Venduti",
            "E il numero totale dei prodotti venduti (se sono venduti in pack si contano i singoli prodotti appartenenti al bundle, es. 24x barrette bundle = 24 prodotti venduti) ",
            { justifyContent: "center", bg: "rgb(206, 206, 218)" },
          )}
          <Columns style={{ width: "100%" }}>
            <Column>
              {this.renderInfo(
                "Totale IT " + quantity_shipped_total_IT,
                "Nr Prodotti venduti Totale proveniente da tutti i canali in Italia  ",
              )}
              {this.renderInfo(
                "Totale EU " + parseInt(quantity_shipped_total - quantity_shipped_total_IT).toString(),
                "Nr Prodotti venduti  Totale  proveniente da tutti i canali in Europa escluso Italia",
              )}

              <br />
              {this.renderInfo(
                "B2C totale " + quantity_shipped_total,
                "Nr Prodotti venduti  Totale  come somma di B2C nostro Sito IT e B2C altri Marketplace",
              )}

              <br />
              {this.renderInfo(
                "B2C nostro sito " + quantity_shipped_total_WOO,
                "Nr Prodotti venduti Totale proveniente dal nostro sito",
              )}
              <br />
              {this.renderInfo(
                "B2C altri marketplace " + parseInt(quantity_shipped_total - quantity_shipped_total_WOO).toString(),
                "Nr Prodotti venduti Totale  proveniente da Amazon + Ebay + Aliexpress",
              )}
              {this.renderInfo(
                "B2C altri marketplace IT " + quantity_shipped_total_ALTRI_IT,
                "Nr Prodotti venduti Totale  proveniente da Amazon + Ebay + Aliexpress in ITALIA",
              )}
              {this.renderInfo(
                "B2C altri marketplace EU " +
                  parseInt(
                    quantity_shipped_total - quantity_shipped_total_WOO - quantity_shipped_total_ALTRI_IT,
                  ).toString(),
                "Nr Prodotti venduti  Totale  proveniente da Amazon + Ebay + Aliexpress in EUROPA escluso ITALIA",
              )}
            </Column>
            <Column>
              {this.renderInfo(
                "B2B totale " + prodottiOfflineProdotti,
                "Nr Totale Prodotti Venduti come somma tra B2B nostri marchi IT, B2B nostri marchi EU, Rebranding IT",
              )}

              <br />
              {this.renderInfo(
                "Rivenditori " + prodottiOfflineIT,
                "Nr Totale Prodotti Venduti calcolato su distinte con causale vendita ed il flag canale Offline + Rivenditori con in anagrafica cliente/ indirizzo di fatturazione /campo Nazione / Italia ",
              )}

              {this.renderInfo(
                "Amazon Vendor " + parseInt(prodottiOfflineProdotti - prodottiOfflineIT).toString(),
                "Nr Totale Prodotti Venduti calcolato su distinte con causale vendita ed il flag canale Offline + Rivenditori",
              )}
            </Column>
          </Columns>
        </div>
        <div style={{ background: "#efeff5" }}>
          {this.renderInfo(
            "AVG Product Cart",
            "E' la media dei prodotti acquistati in un singolo ordine per tutti gli ordini nel periodo selezionato",
            { justifyContent: "center", bg: "rgb(206, 206, 218)" },
          )}

          <Columns style={{ width: "100%" }}>
            <Column>
              {this.renderInfo(
                "Totale IT " + (quantity_shipped_total_IT / acquistiTotaleIT).toFixed(2),
                "AVG cart prodotti proveniente da tutti i canali in Italia ",
              )}
              {this.renderInfo(
                "Totale EU " +
                  ((quantity_shipped_total - quantity_shipped_total_IT) / (acquistiTotale - acquistiTotaleIT)).toFixed(
                    2,
                  ),
                "AVG cart prodotti proveniente da tutti i canali in Europa escluso Italia",
              )}

              <br />
              {this.renderInfo(
                "B2C totale " + (quantity_shipped_total / acquistiTotale).toFixed(2),
                "AVG cart prodotti come somma di B2C nostro Sito IT e B2C altri Marketplace",
              )}

              <br />
              {this.renderInfo(
                "B2C nostro sito " + (quantity_shipped_total_WOO / acquistiTotaleWOO).toFixed(2),
                "AVG cart prodotti proveniente dal nostro sito",
              )}

              <br />
              {this.renderInfo(
                "B2C altri marketplace " +
                  (
                    (quantity_shipped_total - quantity_shipped_total_WOO) /
                    (acquistiTotale - acquistiTotaleWOO)
                  ).toFixed(2),
                "AVG cart prodotti  proveniente da Amazon + Ebay + Aliexpress",
              )}
              {this.renderInfo(
                "B2C altri marketplace IT " + (quantity_shipped_total_ALTRI_IT / acquistiTotaleALTRI_IT).toFixed(2),
                "AVG cart prodotti proveniente da Amazon + Ebay + Aliexpress in ITALIA",
              )}
              {this.renderInfo(
                "B2C altri marketplace EU " +
                  (
                    (quantity_shipped_total - quantity_shipped_total_WOO - quantity_shipped_total_ALTRI_IT) /
                    (acquistiTotale - acquistiTotaleWOO - acquistiTotaleALTRI_IT)
                  ).toFixed(2),
                "AVG cart prodotti  proveniente da Amazon + Ebay + Aliexpress in EUROPA escluso ITALIA",
              )}
            </Column>
            <Column>
              {this.renderInfo(
                "B2B totale " + (prodottiOffline / acquistiOffline).toFixed(2),
                "AVG cart prodotti come somma tra B2B nostri marchi IT, B2B nostri marchi EU, Rebranding IT",
              )}

              <br />
              {this.renderInfo(
                "Rivenditori " + (prodottiOfflineIT / acquistiOfflineIT).toFixed(2),
                "AVG cart prodotti calcolato su distinte con causale vendita ed il flag canale Offline + Rivenditori con in anagrafica cliente/ indirizzo di fatturazione /campo Nazione / Italia ",
              )}
              {this.renderInfo(
                "Amazon Vendor " +
                  ((prodottiOffline - prodottiOfflineIT) / (acquistiOffline - acquistiOfflineIT)).toFixed(2),
                "AVG cart prodotti calcolato su distinte con causale vendita ed il flag canale Offline + Rivenditori",
              )}

              <br />
            </Column>
          </Columns>
        </div>
        <div style={{ background: "#efeff5" }}>
          {this.renderInfo(
            "AVG Cart",
            "E' la media del carrello in un singolo ordine per tutti gli ordini nel periodo selezionato",
            { justifyContent: "center", bg: "rgb(206, 206, 218)" },
          )}
          <Columns style={{ width: "100%" }}>
            <Column>
              {this.renderInfo(
                "Totale IT " +
                  Utils.stringToCurrency(fatturatoIvaIT / acquistiTotaleIT) +
                  " + iva " +
                  Utils.stringToCurrency(fatturatoTotaleIT / acquistiTotaleIT - fatturatoIvaIT / acquistiTotaleIT) +
                  " = " +
                  Utils.stringToCurrency(fatturatoTotaleIT / acquistiTotaleIT),
                "AVG cart proveniente da tutti i canali in Italia  ",
              )}
              {this.renderInfo(
                "Totale EU " +
                  Utils.stringToCurrency(
                    (fatturatoTotaleScorporoIVA - fatturatoIvaIT) / (acquistiTotale - acquistiTotaleIT),
                  ) +
                  " + iva " +
                  Utils.stringToCurrency(
                    (fatturatoTotale - fatturatoTotaleIT) / (acquistiTotale - acquistiTotaleIT) -
                      (fatturatoTotaleScorporoIVA - fatturatoIvaIT) / (acquistiTotale - acquistiTotaleIT),
                  ) +
                  " = " +
                  Utils.stringToCurrency((fatturatoTotale - fatturatoTotaleIT) / (acquistiTotale - acquistiTotaleIT)),
                "AVG cart proveniente da tutti i canali in Europa escluso Italia  ",
              )}

              <br />

              {this.renderInfo(
                "B2C totale " +
                  Utils.stringToCurrency(fatturatoTotaleScorporoIVA / acquistiTotale) +
                  " + iva " +
                  Utils.stringToCurrency(
                    fatturatoTotale / acquistiTotale - fatturatoTotaleScorporoIVA / acquistiTotale,
                  ) +
                  " = " +
                  Utils.stringToCurrency(fatturatoTotale / acquistiTotale),
                "AVG cart come somma di B2C nostro Sito IT e B2C altri Marketplace  ",
              )}

              <br />
              {this.renderInfo(
                "B2C nostro sito " +
                  Utils.stringToCurrency(fatturatoIvaWoo / acquistiTotaleWOO) +
                  " + iva " +
                  Utils.stringToCurrency(fatturatoTotaleWOO / acquistiTotaleWOO - fatturatoIvaWoo / acquistiTotaleWOO) +
                  " = " +
                  Utils.stringToCurrency(fatturatoTotaleWOO / acquistiTotaleWOO),
                "AVG cart proveniente dal nostro sito  ",
              )}

              <br />
              {this.renderInfo(
                "B2C altri marketplace " +
                  Utils.stringToCurrency(
                    (fatturatoTotaleScorporoIVA - fatturatoIvaWoo) / (acquistiTotale - acquistiTotaleWOO),
                  ) +
                  " + iva " +
                  Utils.stringToCurrency(
                    (fatturatoTotale - fatturatoTotaleWOO) / (acquistiTotale - acquistiTotaleWOO) -
                      (fatturatoTotaleScorporoIVA - fatturatoIvaWoo) / (acquistiTotale - acquistiTotaleWOO),
                  ) +
                  " = " +
                  Utils.stringToCurrency((fatturatoTotale - fatturatoTotaleWOO) / (acquistiTotale - acquistiTotaleWOO)),
                "AVG cart proveniente da Amazon + Ebay + Aliexpress  ",
              )}
              {this.renderInfo(
                "B2C altri marketplace IT " +
                  Utils.stringToCurrency(fatturatoIvaALTRI_IT / acquistiTotaleALTRI_IT) +
                  " + iva " +
                  Utils.stringToCurrency(
                    fatturatoTotaleALTRI_IT / acquistiTotaleALTRI_IT - fatturatoIvaALTRI_IT / acquistiTotaleALTRI_IT,
                  ) +
                  " = " +
                  Utils.stringToCurrency(fatturatoTotaleALTRI_IT / acquistiTotaleALTRI_IT),
                "AVG cart proveniente da Amazon + Ebay + Aliexpress in ITALIA",
              )}

              {this.renderInfo(
                "B2C altri marketplace EU " +
                  Utils.stringToCurrency(
                    (fatturatoTotaleScorporoIVA - fatturatoIvaWoo - fatturatoIvaALTRI_IT) /
                      (acquistiTotale - acquistiTotaleWOO - acquistiTotaleALTRI_IT),
                  ) +
                  " + iva " +
                  Utils.stringToCurrency(
                    (fatturatoTotale - fatturatoTotaleWOO - fatturatoTotaleALTRI_IT) /
                      (acquistiTotale - acquistiTotaleWOO - acquistiTotaleALTRI_IT) -
                      (fatturatoTotaleScorporoIVA - fatturatoIvaWoo - fatturatoIvaALTRI_IT) /
                        (acquistiTotale - acquistiTotaleWOO - acquistiTotaleALTRI_IT),
                  ) +
                  " = " +
                  Utils.stringToCurrency(
                    (fatturatoTotale - fatturatoTotaleWOO - fatturatoTotaleALTRI_IT) /
                      (acquistiTotale - acquistiTotaleWOO - acquistiTotaleALTRI_IT),
                  ),
                "AVG cart proveniente da Amazon + Ebay + Aliexpress in EUROPA escluso ITALIA",
              )}
            </Column>
            <Column>
              {this.renderInfo(
                "B2B totale " +
                  Utils.stringToCurrency(
                    (fatturatoOfflineIVA + fatturatoMyrebrandingIVA) / (acquistiOffline + acquistiMyrebranding),
                  ) +
                  " + iva " +
                  Utils.stringToCurrency(
                    (fatturatoOffline + fatturatoMyrebranding) / (acquistiOffline + acquistiMyrebranding) -
                      (fatturatoOfflineIVA + fatturatoMyrebrandingIVA) / (acquistiOffline + acquistiMyrebranding),
                  ) +
                  " = " +
                  Utils.stringToCurrency(
                    (fatturatoOffline + fatturatoMyrebranding) / (acquistiOffline + acquistiMyrebranding),
                  ),
                "AVG cart come somma tra B2B nostri marchi IT, B2B nostri marchi EU, Rebranding IT",
              )}

              <br />
              {this.renderInfo(
                "Rivenditori " +
                  Utils.stringToCurrency(fatturatoOfflineIT_IVA / acquistiOfflineIT) +
                  " + iva " +
                  Utils.stringToCurrency(
                    fatturatoOfflineIT / acquistiOfflineIT - fatturatoOfflineIT_IVA / acquistiOfflineIT,
                  ) +
                  " = " +
                  Utils.stringToCurrency(fatturatoOfflineIT / acquistiOfflineIT),
                "AVG cart calcolato su distinte con causale vendita ed il flag canale Offline + Rivenditori con in anagrafica cliente/ indirizzo di fatturazione /campo Nazione / Italia",
              )}
              {this.renderInfo(
                "Amazon Vendor " +
                  Utils.stringToCurrency(
                    (fatturatoOfflineIVA - fatturatoOfflineIT_IVA) / (acquistiOffline - acquistiOfflineIT),
                  ) +
                  " + iva " +
                  Utils.stringToCurrency(
                    (fatturatoOffline - fatturatoOfflineIT) / (acquistiOffline - acquistiOfflineIT) -
                      (fatturatoOfflineIVA - fatturatoOfflineIT_IVA) / (acquistiOffline - acquistiOfflineIT),
                  ) +
                  " = " +
                  Utils.stringToCurrency(
                    (fatturatoOffline - fatturatoOfflineIT) / (acquistiOffline - acquistiOfflineIT),
                  ),
                "AVG cart calcolato su distinte con causale vendita ed il flag canale Offline + Rivenditori ",
              )}
            </Column>
          </Columns>
        </div>
      </div>
    );
  }

  changeTab(tab) {
    this.setState({ tab: tab });
  }

  checkSearchFied(value, p, products = false) {
    let found = false;
    this.state.searchFields.forEach((s) => {
      const f = s.split("-");
      // console.log(
      //   "az f",
      //   f,
      //   f.length,
      //   f.length === 2 ? p[f[0]][f[1]]?.toString().toLowerCase() : ""
      // );
      if (products) {
        p.products.forEach((pp) => {
          if (
            (!found && String(pp[s]?.toString().toLowerCase()).includes(value.toLowerCase())) ||
            (Array.isArray(pp[s]) && pp[s]?.includes(value.toLowerCase()))
          )
            found = true;
        });
      } else {
        if (
          (!found && String(p[s]?.toString().toLowerCase()).includes(value.toLowerCase())) ||
          (Array.isArray(p[s]) && p[s]?.includes(value.toLowerCase())) ||
          (f.length === 2 && !!p[f[0]] && String(p[f[0]][f[1]]?.toString().toLowerCase()).includes(value.toLowerCase()))
        )
          found = true;
      }
    });
    return found;
  }

  searchData(e) {
    const { value } = e.target;
    this.setState({ searchValue: value }, () => this.applyFilters());
  }
  searchData2(e) {
    const { value } = e.target;
    this.setState({ searchValue2: value }, () => this.applyFilters());
  }
  searchData3(e) {
    const { value } = e.target;
    this.setState({ searchValue3: value }, () => this.applyFilters());
  }
  searchData4(e) {
    const { value } = e.target;
    this.setState({ searchValue4: value }, () => this.applyFilters());
  }
  searchData5(e) {
    const { value } = e.target;
    this.setState({ searchValue5: value }, () => this.applyFilters());
  }

  renderSearch() {
    const { searchFields } = this.state;
    let searchPlaceholder = "cerca";

    return (
      <>
        <Tooltip
          hasArrow
          p={3}
          label={
            <>
              <Heading size="sm">Campi di ricerca</Heading>
              <Divider my={3} />
              <Heading size="xs">
                {searchFields.map((s) => {
                  return <Box>{s}</Box>;
                })}
              </Heading>
            </>
          }
        >
          <Stack spacing={1}>
            <InputGroup size="xs">
              <InputLeftAddon children="filtro" />
              <Input bg="white" placeholder={searchPlaceholder} onChange={(e) => this.searchData(e)} />
            </InputGroup>
            <InputGroup size="xs">
              <InputLeftAddon children="filtro" />
              <Input bg="white" placeholder={searchPlaceholder} onChange={(e) => this.searchData2(e)} />{" "}
            </InputGroup>
            <InputGroup size="xs">
              <InputLeftAddon children="filtro" />
              <Input bg="white" placeholder={searchPlaceholder} onChange={(e) => this.searchData3(e)} />{" "}
            </InputGroup>
            <InputGroup size="xs">
              <InputLeftAddon children="filtro" />
              <Input bg="white" placeholder={searchPlaceholder} onChange={(e) => this.searchData4(e)} />{" "}
            </InputGroup>
            <InputGroup size="xs">
              <InputLeftAddon children="filtro" />
              <Input bg="white" placeholder={searchPlaceholder} onChange={(e) => this.searchData5(e)} />
            </InputGroup>
          </Stack>
        </Tooltip>
      </>
    );
  }
  renderOrdini() {
    const columns = [
      {
        Header: "Order id",
        accessor: "order_id",
      },
      {
        Header: "Tipo",
        accessor: "type",
        style: { textTransform: "capitalize" },
      },
      {
        Header: "Cliente",
        accessor: "buyer_name",
        style: { textTransform: "capitalize" },
      },
      {
        Header: "Prezzo",
        accessor: "item_price",
        type: "currency",
      },
      {
        Header: "Iva",
        accessor: "iva",
      },
      {
        Header: "Spedizione",
        accessor: "shippingCost",
        type: "currency",
      },
      {
        Header: "Data",
        accessor: "purchase_date",
        type: "dateTimestamp",
      },
      {
        Header: "Prodotto",
        accessor: "product",
      },
      {
        Header: "Cod Prodotto",
        accessor: "cod",
      },

      {
        Header: "Qty",
        accessor: "quantity_shipped",
      },

      {
        Header: "Piattaforma",
        accessor: "piattaforma",
      },
      {
        Header: "Cap",
        accessor: "cap",
      },
      {
        Header: "Nazione",
        accessor: "country",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Coupon",
        accessor: "coupon_code",
      },

      {
        Header: "SKU",
        accessor: "sku",
      },
      {
        Header: "Indirizzo",
        accessor: "ship_address_formatted",
      },
      {
        Header: "Telefono",
        accessor: "phone",
      },
    ];

    return (
      <div>
        <ExporterButton
          data={this.state.filteredData}
          filename="MyLab Ordini Kpi"
          title="MyLab Ordini"
          prepare={(o) => {
            return {
              order_id: o.order_id,
              buyer_name: o.buyer_name,
              item_price: o.item_price,
              spedizione: o.shippingCost,
              purchase_date: DateUtils.timestampToDate(o.purchase_date),
              product: o.product,
              product_cod: o.cod,
              quantity_shipped: o.quantity_shipped,
              pezzi: o.pezzi,
              piattaforma: o.piattaforma,
              cap: o.cap,
              country: o.country,
              email: o.email,
              ship_address_1: o.ship_address_1,
              ship_city: o.ship_city,
              bill_address_1: o.bill_address_1,
              bill_city: o.bill_city,
              indirizzo: o.ship_address_formatted,
              telefono: o.phone,
            };
          }}
        />
        <MyTable data={this.state.filteredData} columns={columns} {...this.props} hideButtons />
      </div>
    );
  }

  renderDocumenti() {
    const columns = [
      {
        Header: "Doc id",
        accessor: "uid",
      },
      {
        Header: "Doc n",
        accessor: "codeNumber",
      },
      {
        Header: "Cliente",
        accessor: "contact",
        subaccessor: "name",
        style: { textTransform: "capitalize" },
      },
      {
        Header: "Prezzo",
        accessor: "totalPrice",
        type: "currency",
      },
      {
        Header: "Spedizione",
        accessor: "speseSpedizione",
        type: "currency",
      },
      {
        Header: "Sconto",
        accessor: "scontoTotale",
        type: "currency",
      },
      {
        Header: "Prezzo Finale",
        accessor: "finalPrice",
        type: "currency",
      },
      {
        Header: "Prezzo s.iva",
        accessor: "totalPriceSell",
        type: "currency",
      },
      {
        Header: "Data",
        accessor: "dateTimestamp",
        type: "dateTimestamp",
      },
      {
        Header: "Tipo",
        accessor: "type",
      },
      {
        Header: "Causale",
        accessor: "causale",
      },
    ];

    return (
      <div>
        <MyTable data={this.state.warehouseDocs} columns={columns} {...this.props} hideButtons />
      </div>
    );
  }

  renderClienti() {
    let data = this.state.clientiAggregate.slice();

    const columns = [
      {
        Header: "Cliente",
        accessor: "buyer_name",
        style: { textTransform: "capitalize" },
      },
      {
        Header: "codCliente",
        accessor: "codCliente",
      },
      {
        Header: "Fatturato",
        accessor: "fatturato",
        type: "currency",
      },
      {
        Header: "Fatturato Medio",
        accessor: "fatturato_medio",
        type: "currency",
      },
      {
        Header: "Tot Ordini (periodo)",
        accessor: "num_ordini",
      },
      {
        Header: "Tot Ordini (storico)",
        accessor: "ordini_storico",
      },
      {
        Header: "Tipologia",
        accessor: "nuovo_cliente",
      },
      {
        Header: "Cap",
        accessor: "cap",
      },
      {
        Header: "Nazione",
        accessor: "country",
      },
      {
        Header: "Piattaform",
        accessor: "num_piattaforme",
      },
      {
        Header: "Brands",
        accessor: "num_brands",
      },
    ];
    return (
      <div>
        <MyTable data={data} columns={columns} {...this.props} hideButtons />
      </div>
    );
  }

  renderProdotti() {
    const columns = [
      {
        Header: "Prodotto",
        accessor: "product",
        style: { textTransform: "capitalize" },
      },
      {
        Header: "Codice",
        accessor: "cod",
      },
      {
        Header: "Brand",
        accessor: "brand",
      },
      {
        Header: "Qty Prodotto",
        accessor: "product_qty",
      },
      {
        Header: "Qty in Combo",
        accessor: "combo_qty",
      },
      {
        Header: "Qty Tot",
        accessor: "quantity_shipped",
      },

      {
        Header: "Tot Ordini",
        accessor: "num_ordini",
      },
      {
        Header: "Fatturato",
        accessor: "fatturato",
        type: "currency",
      },
      {
        Header: "Fatturato (senza IVA)",
        accessor: "fatturato_scorporo",
        type: "currency",
      },
    ];
    console.log("AA prodottiAggregate", this.state.prodottiAggregate);
    return (
      <div>
        <MyTable data={this.state.prodottiAggregate} columns={columns} {...this.props} hideButtons />
      </div>
    );
  }

  renderOmaggi() {
    const {
      omaggiSito,
      omaggiAffiliati,
      omaggiPartner,
      omaggiRivenditori,
      omaggiIstituzionali,
      omaggiAltriMarketplace,
      omaggiSitoFatturato,
      omaggiAltriMarketplaceFatturato,
      omaggiAffiliatiFatturato,
      omaggiPartnerFatturato,
      omaggiRivenditoriFatturato,
      omaggiIstituzionaliFatturato,
      omaggiSitoFatturatoScorporo,
      omaggiAltriMarketplaceFatturatoScorporo,
      omaggiAffiliatiFatturatoScorporo,
      omaggiPartnerFatturatoScorporo,
      omaggiRivenditoriFatturatoScorporo,
      omaggiIstituzionaliFatturatoScorporo,
      omaggiAutoconsumo,
      omaggiAutoconsumoFatturato,
      omaggiAutoconsumoFatturatoScorporo,
      warehouseDocs,
    } = this.state;

    const omaggi = warehouseDocs.filter(
      (d) => d.type == DocumentType.DISTINTA && (d.causale === "Omaggio" || d.causale === "Autoconsumo"),
    );
    console.log("vv omaggio", omaggi, warehouseDocs);
    const om = [];
    omaggi.forEach((o) => {
      o.products.forEach((p) => {
        if (!om[p.sku]) {
          om[p.sku] = {
            product: p.name,
            cod: p.sku,
            brand: p.brand,
            canaleVenditaMultiplo: o.canaleVenditaMultiplo ? [o.canaleVenditaMultiplo] : [],
            quantity_shipped: p.quantity,
            fatturato:
              p.quantity * (parseFloat(p.prezzo_acquisto) + (parseFloat(p.prezzo_acquisto) * (p.iva || 10)) / 100),
            scorporo_iva: p.quantity * parseFloat(p.prezzo_acquisto),
            prezzo_acquisto: parseFloat(p.prezzo_acquisto),
          };
        } else {
          const fatturato = om[p.sku].fatturato + p.quantity * parseFloat(p.prezzo_acquisto);
          om[p.sku] = {
            product: p.name,
            cod: p.sku,
            brand: p.brand,
            canaleVenditaMultiplo: o.canaleVenditaMultiplo
              ? [...new Set([...om[p.sku].canaleVenditaMultiplo, o.canaleVenditaMultiplo])]
              : om[p.sku].canaleVenditaMultiplo,

            quantity_shipped: om[p.sku].quantity_shipped + p.quantity,
            fatturato: parseFloat(fatturato) + (parseFloat(fatturato) * (p.iva || 10)) / 100,
            scorporo_iva: fatturato,
          };
        }
      });
    });
    console.log("vv MM", om);
    const list = [];
    Object.keys(om).forEach(function (sku) {
      om[sku].canaleVenditaMultiplo = om[sku].canaleVenditaMultiplo.join("-");
      list.push(om[sku]);
    });
    const filteredOm = this.searchFilter(list);
    console.log("vv filteredOm", filteredOm);

    const columns = [
      {
        Header: "Prodotto",
        accessor: "product",
        style: { textTransform: "capitalize" },
      },
      {
        Header: "Codice",
        accessor: "cod",
      },
      {
        Header: "Brand",
        accessor: "brand",
      },
      {
        Header: "Canale",
        accessor: "canaleVenditaMultiplo",
      },
      {
        Header: "Qty",
        accessor: "quantity_shipped",
      },

      // {
      //   Header: "Tot Omaggi",
      //   accessor: "num_ordini"
      // },
      {
        Header: "Imponibile",
        accessor: "fatturato",
        type: "currency",
      },
      {
        Header: "Scorporo Iva",
        accessor: "scorporo_iva",
        type: "currency",
      },
      {
        Header: "Prezzo",
        accessor: "prezzo_acquisto",
        type: "currency",
      },
    ];
    return (
      <div>
        <div
          style={{
            background: "rgb(152 152 158)",
            marginTop: "20px",
            height: "40px",
          }}
          className="ta-c"
        >
          <b>PERFORMANCE OMAGGI</b>
        </div>

        <div style={{ background: "#efeff5" }}>
          <Columns style={{ width: "100%" }}>
            <Column>
              {this.renderInfo("Nr. Totale Omaggi", "E' il numero di prodotti omaggiati per ogni canale", {
                justifyContent: "center",
                bg: "rgb(206, 206, 218)",
              })}
              {this.renderInfo(
                "Omaggi Sito " + omaggiSito,
                "Il Numero di Omaggi su distinte con causale Omaggi e flag canale Sito",
              )}
              {this.renderInfo(
                "Omaggi Altri Marketplace " + omaggiAltriMarketplace,
                "Il Numero di Omaggi su distinte con causale Omaggi e flag canale Altri Marketplace",
              )}
              {this.renderInfo(
                "Omaggi Affiliati " + omaggiAffiliati,
                "Il Numero di Omaggi su distinte con causale Omaggi e flag canale Affiliati",
              )}
              {this.renderInfo(
                "Omaggi Partner " + omaggiPartner,
                "Il Numero di Omaggi su distinte con causale Omaggi e flag canale Partner",
              )}
              {this.renderInfo(
                "Omaggi Rivenditori " + omaggiRivenditori,
                "Il Numero di Omaggi su distinte con causale Omaggi e flag canale Rivenditori",
              )}
              {this.renderInfo(
                "Omaggi Istituzionali " + omaggiIstituzionali,
                "Il Numero di Omaggi su distinte con causale Omaggi e flag canale Istituzionali",
              )}
              {this.renderInfo(
                "Omaggi Autoconsumo " + omaggiAutoconsumo,
                "Il Numero di Omaggi su distinte con causale Autoconsumo",
              )}
            </Column>
            <Column>
              {this.renderInfo("Valore Totale Omaggi", "E' il valore dei prodotti omaggiati per ogni canale ", {
                justifyContent: "center",
                bg: "rgb(206, 206, 218)",
              })}
              {this.renderInfo(
                "Omaggi Sito " +
                  Utils.stringToCurrency(omaggiSitoFatturatoScorporo) +
                  " + iva " +
                  parseFloat(omaggiSitoFatturato - omaggiSitoFatturatoScorporo)
                    .toFixed(2)
                    .toString() +
                  " = " +
                  Utils.stringToCurrency(omaggiSitoFatturato),
                "Il Valore (scorporato netto iva + Iva) degli Omaggi su distinte con causale Omaggi e flag canale Sito (prendi valore da file costo prodotti) ",
              )}
              {this.renderInfo(
                "Omaggi Altri Marketplace " +
                  Utils.stringToCurrency(omaggiAltriMarketplaceFatturatoScorporo) +
                  " + iva " +
                  parseFloat(omaggiAltriMarketplaceFatturato - omaggiAltriMarketplaceFatturatoScorporo)
                    .toFixed(2)
                    .toString() +
                  " = " +
                  Utils.stringToCurrency(omaggiAltriMarketplaceFatturato),
                "Il Valore (scorporato netto iva + Iva) degli Omaggi su distinte con causale Omaggi e flag canale Altri Marketplace (prendi valore da file costo prodotti)",
              )}
              {this.renderInfo(
                "Omaggi Affiliati " +
                  Utils.stringToCurrency(omaggiAffiliatiFatturatoScorporo) +
                  " + iva " +
                  parseFloat(omaggiAffiliatiFatturato - omaggiAffiliatiFatturatoScorporo)
                    .toFixed(2)
                    .toString() +
                  " = " +
                  Utils.stringToCurrency(omaggiAffiliatiFatturato),
                "Il Valore (scorporato netto iva + Iva) degli Omaggi su distinte con causale Omaggi e flag canale Affiliati (prendi valore da file costo prodotti)",
              )}
              {this.renderInfo(
                "Omaggi Partner " +
                  Utils.stringToCurrency(omaggiPartnerFatturatoScorporo) +
                  " + iva " +
                  parseFloat(omaggiPartnerFatturato - omaggiPartnerFatturatoScorporo)
                    .toFixed(2)
                    .toString() +
                  " = " +
                  Utils.stringToCurrency(omaggiPartnerFatturato),
                "Il Valore (scorporato netto iva + Iva) degli Omaggi su distinte con causale Omaggi e flag canale Partner (prendi valore da file costo prodotti)",
              )}
              {this.renderInfo(
                "Omaggi Rivenditori " +
                  Utils.stringToCurrency(omaggiRivenditoriFatturatoScorporo) +
                  " + iva " +
                  parseFloat(omaggiRivenditoriFatturato - omaggiRivenditoriFatturatoScorporo)
                    .toFixed(2)
                    .toString() +
                  " = " +
                  Utils.stringToCurrency(omaggiRivenditoriFatturato),
                "Il Valore (scorporato netto iva + Iva) degli Omaggi su distinte con causale Omaggi e flag canale Rivenditori (prendi valore da file costo prodotti)",
              )}
              {this.renderInfo(
                "Omaggi Istituzionali " +
                  Utils.stringToCurrency(omaggiIstituzionaliFatturatoScorporo) +
                  " + iva " +
                  parseFloat(omaggiIstituzionaliFatturato - omaggiIstituzionaliFatturatoScorporo)
                    .toFixed(2)
                    .toString() +
                  " = " +
                  Utils.stringToCurrency(omaggiIstituzionaliFatturato),
                "Il Valore (scorporato netto iva + Iva) degli Omaggi su distinte con causale Omaggi e flag canale Istituzionali (prendi valore da file costo prodotti)",
              )}
              {this.renderInfo(
                "Omaggi Autoconsumo " +
                  Utils.stringToCurrency(omaggiAutoconsumoFatturatoScorporo) +
                  " + iva " +
                  parseFloat(omaggiAutoconsumoFatturato - omaggiAutoconsumoFatturatoScorporo)
                    .toFixed(2)
                    .toString() +
                  " = " +
                  Utils.stringToCurrency(omaggiAutoconsumoFatturatoScorporo),
                "Il Valore (scorporato netto iva + Iva) degli Omaggi su distinte con causale Autoconsumo (prendi valore da file costo prodotti)",
              )}
            </Column>
          </Columns>
        </div>
        <MyTable data={filteredOm} columns={columns} {...this.props} hideButtons />
      </div>
    );
  }
  renderCombo() {
    const columns = [
      {
        Header: "Prodotto",
        accessor: "product",
        style: { textTransform: "capitalize" },
      },
      {
        Header: "Codice",
        accessor: "cod",
      },
      {
        Header: "Qty",
        accessor: "quantity_shipped",
      },
      {
        Header: "Pezzi",
        accessor: "pezzi",
      },

      {
        Header: "Tot Ordini",
        accessor: "num_ordini",
      },
      {
        Header: "Fatturato",
        accessor: "fatturato",
        type: "currency",
      },
    ];
    return (
      <div>
        <MyTable data={this.state.comboAggregate} columns={columns} {...this.props} hideButtons />
      </div>
    );
  }

  renderGrafici() {
    return (
      <DReportGrafici
        data={this.state.filteredData}
        prodotti={this.state.prodottiAggregate}
        clientiTotaliAggregate={this.state.clientiTotaliAggregate}
        lifeTimeValue={this.state.lifeTimeValue}
        clienti={this.state.clientiAggregate}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        calculating={this.state.calculating}
        {...this.props}
      />
    );
  }

  renderLVG() {
    console.log("AA render lvg", this.state.clientiTotaliAggregate, this.state.clientiTotaliLVG);
    return (
      <DReportLVG
        clientiTotaliAggregate={this.state.clientiTotaliAggregate}
        clientiTotaliLVG={this.state.clientiTotaliLVG}
      />
    );
  }

  doEscludiStorico() {
    console.log("CC doEscludiStorico");
    this.setState({ escludiStorico: !this.state.escludiStorico }, () => this.loadData());
  }

  renderSkeletonText(v, t, hideSkeleton = false) {
    return (
      <>
        <Box my={5}>
          <Heading size="sm" w="fit-content" textAlign={"center"} m={"auto"}>
            {t}
          </Heading>

          <Progress value={v} />
        </Box>
        {!hideSkeleton && (
          <Box padding="6" boxShadow="lg" bg="white">
            <SkeletonCircle size="10" />
            <SkeletonText mt="4" noOfLines={4} spacing="4" />
          </Box>
        )}
      </>
    );
  }

  exportYear() {
    const jsonData = this.state.data;
    const fileData = JSON.stringify(jsonData);
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "2023.json";
    link.href = url;
    link.click();
  }
  render() {
    if (this.state.loadingCountries) return <Loader />;
    return (
      <div>
        <Container>
          <Heading size="lg" textAlign={"center"}>
            Report KPI
          </Heading>

          <Columns className="admin-header mt-5">
            {this.props.ware.debug && (
              <Column>
                <Button onClick={() => this.deleteDB()} className="is-primary">
                  ELIMINA DAL DB
                </Button>

                <Help>
                  Deleted:{this.state.deletedRow}/{this.state.filteredData.length}
                </Help>
              </Column>
            )}
          </Columns>

          {this.state.tab != "lvg" && (
            <Box p={5} bg="#75759047" borderRadius="md">
              <Flex justify={"space-between"}>
                <Box>
                  <DateRangePicker
                    startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                    isOutsideRange={() => false}
                    displayFormat="DD MMM YYYY"
                    startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                    endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                    onDatesChange={({ startDate, endDate }) => this.onDatesChange(startDate, endDate)} // PropTypes.func.isRequired,
                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={(focusedInput) => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                    startDatePlaceholderText={"Data inizio"}
                    endDatePlaceholderText={"Data fine"}
                    minimumNights={0}
                  />
                  {this.state.dateChanged && (
                    <Button
                      isLoading={this.state.loading}
                      ml={2}
                      colorScheme="green"
                      onClick={() => this.caricaDati()}
                      size="sm"
                    >
                      <FontAwesomeIcon className="icn" icon={["fa", "check"]} />
                    </Button>
                  )}
                  <Flex gap={2} mt={5} align="center">
                    <Switch onChange={() => this.doEscludiStorico()} isChecked={!this.state.escludiStorico} />
                    <Heading size="sm">Storico</Heading>
                    {this.state.storicoData ? (
                      <Heading size="sm">STORICO CARICATO</Heading>
                    ) : !this.state.loadingStorico ? (
                      <input type="file" onChange={this.handleChange} multiple />
                    ) : (
                      <Loader />
                    )}

                    {/* <Button onClick={() => this.exportYear()}>esporta</Button> */}
                  </Flex>
                </Box>
                <Stack>
                  <Select
                    style={{ width: "100%" }}
                    value={this.state.brandFilter}
                    w="100px"
                    onChange={(v) => {
                      console.log("ww change brand", v.target.value);
                      this.setState({ brandFilter: v.target.value }, () => this.applyFilters());
                    }}
                  >
                    <option value="-">TUTTI I BRAND</option>
                    <option value="T-REX INTEGRATORI">T-REX</option>
                    <option value="NUTRACLE">NUTRACLE</option>
                    <option value="SUPRAPET">SUPRAPET</option>
                    <option value="MYLAB NUTRITION">MYLAB</option>
                  </Select>
                  <Select
                    style={{ width: "100%" }}
                    value={this.state.countryFilter}
                    onChange={(v) => {
                      console.log("ww change country", v.target.value);
                      this.setState({ countryFilter: v.target.value }, () => this.applyFilters());
                    }}
                  >
                    <option value="-">TUTTI I PAESI</option>
                    <option value="it">ITALIA</option>
                    <option value="fr">FRANCIA</option>
                    <option value="de">GERMANIA</option>
                    <option value="nl">OLANDA</option>
                    <option value="es">SPAGNA</option>
                    <option value="be">BELGIO</option>
                    <option value="pl">POLONIA</option>
                    <option value="se">SVEZIA</option>
                    <option value="uk">UK</option>
                  </Select>
                  <Select
                    style={{ width: "100%" }}
                    value={this.state.canaleFilter}
                    onChange={(v) => {
                      console.log("ww change canale", v.target.value);
                      this.setState({ canaleFilter: v.target.value }, () => this.applyFilters());
                    }}
                  >
                    <option value="-">TUTTI I CANALI</option>
                    <option value="WOO">WOOCOMMERCE</option>
                    <option value="ALTRI">ALTRI MARKETPLACE</option>
                    <option value="B2B">B2B</option>
                    <option value="Rebranding">REBRANDING</option>
                  </Select>
                </Stack>
                <Box w="30%">{this.renderSearch()}</Box>
              </Flex>
            </Box>
          )}

          {this.state.loading ? (
            <>
              {this.state.yearsLoaded?.map((y) => {
                return this.renderSkeletonText(20, "Anno " + y.year + " records " + y.dati, true);
              })}
              {this.state.yearLoading &&
                this.renderSkeletonText(
                  20,
                  "Loading storico anno " + this.state.monthLoading + " - " + this.state.yearLoading,
                  true,
                )}
              {this.renderSkeletonText(20, "Loading B2C data")}
            </>
          ) : !!this.state.formatting ? (
            this.renderSkeletonText(60, "Formattando data")
          ) : !!this.state.loadB2B ? (
            this.renderSkeletonText(70, "Loading B2B data")
          ) : !!this.state.calculating ? (
            this.renderSkeletonText(80, "Calcolando data")
          ) : (
            <div>
              {this.state.tab != "lvg" && <div className="ml-40 mtb-20">{this.renderTotali()}</div>}
              <Tabs className="mt-30">
                <TabList>
                  <Tab className={this.state.tab === "ordini" ? "is-active" : ""}>
                    <TabLink onClick={() => this.changeTab("ordini")}>
                      <span>ORDINI ({this.state.filteredData?.length})</span>
                    </TabLink>
                  </Tab>
                  {this.props.ware?.user?.uid === "7l7FslhnMIgDJvEt3H2VWkG70LR2" && (
                    <Tab className={this.state.tab === "documenti" ? "is-active" : ""}>
                      <TabLink onClick={() => this.changeTab("documenti")}>
                        <span>DOCUMENTI ({this.state.warehouseDocs?.length})</span>
                      </TabLink>
                    </Tab>
                  )}
                  <Tab className={this.state.tab === "clienti" ? "is-active" : ""}>
                    <TabLink onClick={() => this.changeTab("clienti")}>
                      <span>CLIENTI ({this.state.clientiAggregate?.length})</span>
                    </TabLink>
                  </Tab>
                  <Tab className={this.state.tab === "prodotti" ? "is-active" : ""}>
                    <TabLink onClick={() => this.changeTab("prodotti")}>
                      <span>PRODOTTI ({this.state.prodottiAggregate?.length})</span>
                    </TabLink>
                  </Tab>
                  <Tab className={this.state.tab === "combo" ? "is-active" : ""}>
                    <TabLink onClick={() => this.changeTab("combo")}>
                      <span>COMBO ({this.state.comboAggregate?.length})</span>
                    </TabLink>
                  </Tab>
                  <Tab className={this.state.tab === "omaggi" ? "is-active" : ""}>
                    <TabLink onClick={() => this.changeTab("omaggi")}>
                      <span>OMAGGI </span>
                    </TabLink>
                  </Tab>

                  <Tab className={this.state.tab === "kpi" ? "is-active" : ""}>
                    <TabLink onClick={() => this.changeTab("kpi")}>
                      <span>KPI</span>
                    </TabLink>
                  </Tab>
                  <Tab className={this.state.tab === "grafici" ? "is-active" : ""}>
                    <TabLink onClick={() => this.changeTab("grafici")}>
                      <span>GRAFICI</span>
                    </TabLink>
                  </Tab>
                  <Tab className={this.state.tab === "lvg" ? "is-active" : ""}>
                    <TabLink
                      onClick={() => {
                        if (!this.state.clientiTotaliLVG) this.doEscludiStorico();
                        this.changeTab("lvg");
                      }}
                    >
                      <span>LVG</span>
                    </TabLink>
                  </Tab>
                </TabList>
              </Tabs>
              <div>
                {this.state.tab == "ordini" && this.renderOrdini()}
                {this.state.tab == "documenti" && this.renderDocumenti()}
                {this.state.tab == "clienti" && this.renderClienti()}
                {this.state.tab == "prodotti" && this.renderProdotti()}
                {this.state.tab == "combo" && this.renderCombo()}
                {this.state.tab == "omaggi" && this.renderOmaggi()}
                {this.state.tab == "kpi" && this.renderKPI()}
                {this.state.tab == "grafici" && this.renderGrafici()}
                {this.state.tab == "lvg" && this.renderLVG()}
              </div>
            </div>
          )}
        </Container>
      </div>
    );
  }
}

export default withModalsCtx(withRouter(withAdminCtx(withWareCtx(DReportsKPI))));

// exportToOdoo() {
//   console.log("AA export to odoo");
//   const { ordiniAggregate, clientiAggregate } = this.state;
//   console.log("AA clienti", clientiAggregate);
//   console.log("AA ordini", ordiniAggregate);

//   let { filteredData } = this.state;
//   filteredData = filteredData.sort((a, b) => {
//     if (a.order_id > b.order_id) return 1;
//     if (a.order_id < b.order_id) return -1;
//     return 0;
//   });

//   let data = [];
//   let index = 0;
//   let previousOrder;
//   filteredData.forEach(o => {
//     Utils.log("AA d", o);
//     const salesTeam =
//       o.piattaforma === "AMAZON"
//         ? "Amazon"
//         : o.piattaforma === "WOO"
//         ? "WooCommerce"
//         : "Ebay";

//     const tags =
//       o.type === "REFUND"
//         ? "RIMBORSO"
//         : o.type === "RETURN"
//         ? "RESO"
//         : o.type === "SALE"
//         ? "VENDITA"
//         : "";

//     if (!o.cod) return;
//     let d;
//     if (index > 0 && previousOrder.orderUID === o.orderUID) {
//       d = {
//         checkOrder: o.order_id,
//         checkUID: o.orderUID,
//         test: "accoda",
//         order_id: "",
//         external_id: "",
//         tag_ids: "",
//         "partner_id/id": "",
//         date_order: "",
//         product: o.product,
//         "order_line/product_id": o.cod,
//         "order_line/product_uom_qty": o.quantity_shipped,
//         // "order_line/product_uom": "Unità",
//         "order_line/price_unit": o.scorporo_iva / o.quantity_shipped,
//         origin: "",
//         medium_id: "",
//         team_id: ""
//       };
//     } else {
//       if (
//         index > 0 &&
//         !!previousOrder.shippingCost &&
//         previousOrder.shippingCost != 0
//       ) {
//         data.push({
//           checkOrder: previousOrder.order_id,
//           checkUID: previousOrder.orderUID,
//           test: "spese spedizione",
//           order_id: "",
//           external_id: "",
//           tag_ids: "",
//           "partner_id/id": "",
//           date_order: "",
//           product: "Spese",
//           "order_line/product_id":
//             previousOrder.piattaforma_2 === "PRIME" ? "SSP" : "SDS",
//           "order_line/product_uom_qty": 1,
//           // "order_line/product_uom": "Unità",
//           "order_line/price_unit": previousOrder.shippingCost / 1.22,
//           origin: "",
//           medium_id: "",
//           team_id: ""
//         });
//       }

//       d = {
//         checkOrder: o.order_id,
//         checkUID: o.orderUID,
//         test: "prima riga",
//         order_id: o.order_id,
//         external_id: o.orderUID,
//         tag_ids: tags,
//         "partner_id/id": o.codCliente,
//         date_order: moment(DateUtils.timestampToDate(o.purchase_date)).format(
//           "DD-MM-YYYY"
//         ),
//         product: o.product,
//         "order_line/product_id": o.cod,
//         "order_line/product_uom_qty": o.quantity_shipped,
//         // "order_line/product_uom": "Unità",
//         "order_line/price_unit": o.scorporo_iva / o.quantity_shipped,
//         origin: o.coupon_code,
//         medium_id: "Import manuale",
//         team_id: salesTeam,
//         pezzi: o.pezzi,
//         piattaforma: o.piattaforma,
//         cap: o.cap,
//         country: o.country,
//         email: o.email,
//         ship_address_1: o.ship_address_1,
//         ship_city: o.ship_city,
//         bill_address_1: o.bill_address_1,
//         bill_city: o.bill_city,
//         indirizzo: o.ship_address_formatted,
//         telefono: o.phone
//       };
//     }

//     data.push(d);
//     index++;
//     previousOrder = o;
//   });

//   if (
//     !!previousOrder.shippingCost &&
//     previousOrder.shippingCost != 0 &&
//     index > 0
//   ) {
//     data.push({
//       checkOrder: previousOrder.order_id,
//       checkUID: previousOrder.orderUID,
//       test: "accoda finale",
//       order_id: "",
//       external_id: "",
//       tag_ids: "",
//       "partner_id/id": "",
//       date_order: "",
//       product: "Spese",
//       "order_line/product_id":
//         previousOrder.piattaforma_2 === "PRIME" ? "SSP" : "SDS",
//       "order_line/product_uom_qty": 1,
//       "order_line/product_uom": "Units",
//       "order_line/price_unit": previousOrder.shippingCost / 1.22,
//       origin: "",
//       medium_id: "",
//       team_id: ""
//     });
//   }

//   let options = {
//     fieldSeparator: ",",
//     quoteStrings: '"',
//     filename: "MyLab Ordini Odoo",
//     decimalSeparator: ".",
//     showLabels: true,
//     showTitle: false,
//     title: "MyLab Ordini Odoo",
//     useTextFile: false,
//     useBom: true,
//     useKeysAsHeaders: true
//   };

//   let csvExporter = new ExportToCsv(options);

//   csvExporter.generateCsv(data);

//   //CLIENTI
//   data = [];

//   clientiAggregate.forEach(o => {
//     Utils.log("AA cliente", o);
//     let d;

//     const cliente = o.buyer_name;

//     //split the above string into an array of strings
//     //whenever a blank space is encountered

//     const arr = cliente.split(" ");

//     //loop through each element of the array and capitalize the first letter.

//     for (var i = 0; i < arr.length; i++) {
//       arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
//     }

//     //Join all the elements of the array back into a string
//     //using a blankspace as a separator
//     const clienteCapitalize = arr.join(" ");

//     const tags = o.piattaforme.includes("AMAZON")
//       ? "AMZ"
//       : o.piattaforme.includes("WOO")
//       ? "WOO"
//       : o.piattaforme.includes("Ebay")
//       ? "EBAY"
//       : "";

//     d = {
//       name: clienteCapitalize,
//       external_id: o.codCliente,
//       category_id: tags,
//       cap: o.cap,
//       country: o.country_code || "",
//       email: o.email,
//       ship_address_1: o.ship_address_1,
//       ship_city: o.ship_city,
//       bill_address_1: o.bill_address_1,
//       bill_city: o.bill_city,
//       indirizzo: o.ship_address_formatted,
//       telefono: o.phone
//     };

//     data.push(d);
//   });

//   options = {
//     fieldSeparator: ",",
//     quoteStrings: '"',
//     filename: "MyLab Clienti Odoo",
//     decimalSeparator: ".",
//     showLabels: true,
//     showTitle: false,
//     title: "MyLab Clienti Odoo",
//     useTextFile: false,
//     useBom: true,
//     useKeysAsHeaders: true
//   };

//   csvExporter = new ExportToCsv(options);

//   csvExporter.generateCsv(data);
// }
